import React from 'react';
const apiUrl = process.env.REACT_APP_API_BASE_URL;

function JockeyHistory(props) {
  return (
    <div>
      <h1>JOCKEY HISTORY</h1>
    </div>
  );
}

export default JockeyHistory;
