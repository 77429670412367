import React, { useState } from 'react';
import axios from 'axios';


const apiUrl = process.env.REACT_APP_API_BASE_URL;


function InsertColumns() {
  const [tableName, setTableName] = useState('');
  const [newColumns, setNewColumns] = useState('');
  const [existingColumns, setExistingColumns] = useState([]);
  const [createdColumns, setCreatedColumns] = useState([]);
  const [isComplete, setIsComplete] = useState(false);

  const getColumns = async () => {
    try {
      const response = await axios.get(`${apiUrl}/${tableName}/fields`);
      setExistingColumns(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const addColumns = async () => {
    setIsComplete(false);
    setCreatedColumns([]);
    const columnsToAdd = newColumns.split(',').map((column) => column.trim());
    const createdCols = [];
    for (const col of columnsToAdd) {
      if (!existingColumns.includes(col)) {
        try {
          const response = await axios.post(`${apiUrl}/${tableName}/addColumn`, { columnName: col });
          createdCols.push(col);
          console.log(`Created column '${col}' in '${tableName}'`);
        } catch (error) {
          console.error(error);
        }
      }
    }
    setCreatedColumns(createdCols);
    setIsComplete(true);
  };

  return (
    <div>
      <h2>Add Columns to Table</h2>
      <label htmlFor="tableNameInput">Table Name:</label>
      <input
        id="tableNameInput"
        type="text"
        value={tableName}
        onChange={(event) => setTableName(event.target.value)}
      />
      <button onClick={getColumns}>Get Existing Columns</button>
      <ul>
        {existingColumns.map((col) => (
          <li key={col}>{col}</li>
        ))}
      </ul>

      <label htmlFor="newColumnsInput">New Columns (comma-separated):</label>
      <input
        id="newColumnsInput"
        type="text"
        value={newColumns}
        onChange={(event) => setNewColumns(event.target.value)}
      />

      <button onClick={addColumns}>Add Columns to Table</button>

      {isComplete && (
        <p>
          Created columns:{' '}
          {createdColumns.length === 0 ? 'None' : createdColumns.map((col) => `'${col}'`).join(', ')}
        </p>
      )}
    </div>
  );
}

export default InsertColumns;

// import React, { useState } from 'react';
// import axios from 'axios';

// function AddColumns() {
//   const [tableName, setTableName] = useState('');
//   const [newColumns, setNewColumns] = useState('');
//   const [columnType, setColumnType] = useState('text');
//   const [existingColumns, setExistingColumns] = useState([]);
//   const [createdColumns, setCreatedColumns] = useState([]);
//   const [isComplete, setIsComplete] = useState(false);

//   const getColumns = async () => {
//     try {
//       const response = await axios.get(`${apiUrl}/${tableName}/fields`);
//       setExistingColumns(response.data);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const addColumns = async () => {
//     setIsComplete(false);
//     setCreatedColumns([]);
//     const columnsToAdd = newColumns.split(',').map((column) => column.trim());
//     const createdCols = [];
//     for (const col of columnsToAdd) {
//       if (!existingColumns.includes(col)) {
//         try {
//           const response = await axios.post(`${apiUrl}/${tableName}/addColumn`, {
//             columnName: col,
//             columnType: columnType,
//           });
//           createdCols.push(col);
//           console.log(`Created column '${col}' in '${tableName}'`);
//         } catch (error) {
//           console.error(error);
//         }
//       }
//     }
//     setCreatedColumns(createdCols);
//     setIsComplete(true);
//   };

//   return (
//     <div>
//       <h2>Add Columns to Table</h2>
//       <label htmlFor="tableNameInput">Table Name:</label>
//       <input
//         id="tableNameInput"
//         type="text"
//         value={tableName}
//         onChange={(event) => setTableName(event.target.value)}
//       />
//       <button onClick={getColumns}>Get Existing Columns</button>
//       <ul>
//         {existingColumns.map((col) => (
//           <li key={col}>{col}</li>
//         ))}
//       </ul>

//       <label htmlFor="newColumnsInput">New Columns (comma-separated):</label>
//       <input
//         id="newColumnsInput"
//         type="text"
//         value={newColumns}
//         onChange={(event) => setNewColumns(event.target.value)}
//       />

//       <label htmlFor="columnTypeInput">Column Type:-ADD COL TYPE TO END OF csv NAME ABOVE FOR NOW</label>
//       <select id="columnTypeInput" value={columnType} onChange={(event) => setColumnType(event.target.value)}>
//       <option value="INTEGER">INTEGER</option>
//         <option value="TEXT">TEXT</option>
//         <option value="NUMERIC">NUMERIC</option>
//         <option value="REAL">REAL</option>
//         <option value="BLOB">BLOB</option>
//         {/* Add more options as needed */}
//       </select>

//       <button onClick={addColumns}>Add Columns to Table</button>

//       {isComplete && (
//         <p>
//           Created columns:{' '}
//           {createdColumns.length === 0 ? 'None' : createdColumns.map((col) => `'${col}'`).join(', ')}
//         </p>
//       )}
//     </div>
//   );
// }

// export default AddColumns;
