
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../Styles/dataTables.css";
import Record from "./Record";
import FormatKey from '../Modules/FormatKey';


const apiUrl = process.env.REACT_APP_API_BASE_URL;



function Races() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRaces, setSelectedRaces] = useState([]);
  const [selectedSearchColumn, setSelectedSearchColumn] = useState(""); // New state for selected search column
  const [Pagesize, setPagesize] = useState(5);
  const [sortConfig, setSortConfig] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [table, setTable] = useState("races");
  const [totalRecords, setTotalRecords] = useState(0); 
  const navigate = useNavigate();
  const { id } = useParams();

  function handleSetTable(props) {
    setTable("races");
  }


  useEffect(() => {
    let apiEndpoint = `${apiUrl}/races`;

    if (id) {
      apiEndpoint = `${apiUrl}/races/${id}`;
    }

    // Add a query parameter to limit the number of records to 1000
    const queryParams = { limit: 1000 };

    axios
      .get(apiEndpoint, { params: queryParams }) // Pass queryParams as params
      .then((response) => {
        const data = Array.isArray(response.data)
          ? response.data
          : [response.data];
        setData(data);
        const allColumns = Object.keys(data[0]);
        const initialColumns = allColumns.slice(1, 10);
        setColumns(allColumns);
        setSelectedColumns(initialColumns);
        setTotalRecords(response.data.length); // Set total records
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

     // Fetch the next batch of records using the start and limit query parameters
  const loadNextBatch = () => {
    const nextStartIndex = data.length; // Calculate the start index for the next batch
    const nextPageSize = 5000; // Define the size of the next batch

    // Fetch the next batch of records using the start and limit query parameters
    const nextApiEndpoint = `${apiUrl}/races?start=${nextStartIndex}&limit=${nextPageSize}`;

    axios
      .get(nextApiEndpoint)
      .then((response) => {
        const newData = Array.isArray(response.data)
          ? response.data
          : [response.data];

        // Append the new batch of records to the existing data
        setData((prevData) => [...prevData, ...newData]);
        setTotalRecords(prevTotalRecords => prevTotalRecords + newData.length); // Update total records
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleColumnSelect(event) {
    const selected = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedColumns(selected);
  }

  function handleRaceSelect(e) {
    const options = e.target.options;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedRaces(selected);
  }

  function handlePagesizeSelect(e) {
    const Pagesize = parseInt(e.target.value);
    setPagesize(Pagesize);
  }

  function handleSort(key) {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  }

  function sortData(data) {
    if (!sortConfig) {
      return data;
    }

    const sortedData = [...data];
    const { key, direction } = sortConfig;

    sortedData.sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    return sortedData;
  }

  function filterData(data) {
    let filteredData = data;

    if (selectedRaces.length > 0) {
      filteredData = filteredData.filter((row) =>
        selectedRaces.includes(row.race_name)
      );
    }

    if (searchTerm && selectedSearchColumn) { // Check if a column is selected
      filteredData = filteredData.filter((row) => {
        const columnValue = row[selectedSearchColumn]
          ? row[selectedSearchColumn].toString().toLowerCase()
          : "";
        const searchTermLowerCase = searchTerm.toLowerCase();
        return columnValue.includes(searchTermLowerCase);
      });
    } else if (searchTerm) { // If no column is selected, search across all columns
      filteredData = filteredData.filter((row) => {
        return Object.keys(row).some((key) => {
          const columnValue = row[key] ? row[key].toString().toLowerCase() : "";
          const searchTermLowerCase = searchTerm.toLowerCase();
          return columnValue.includes(searchTermLowerCase);
        });
      });
    }

    return filteredData;
  }
  

  const totalRows = filterData(data).length;
  const totalPages = Math.ceil(totalRows / Pagesize);
  const startIndex = (currentPage - 1) * Pagesize;
  const endIndex = startIndex + Pagesize;
  const currentPageData = filterData(sortData(data)).slice(
    startIndex,
    endIndex
  );

  function handleSearch(e) {
    setSearchTerm(e.target.value);
  }


  function handleSearchColumnSelect(event) {
    setSelectedSearchColumn(event.target.value);
  }

  function handlePageChange(page) {
    const newPage = page <= 0 ? 1 : page > totalPages ? totalPages : page;
    setCurrentPage(newPage);
    const newStartIndex = (newPage - 1) * Pagesize;
  }

  function handleNextTenPages() {
    const newPage = currentPage + 1;
    handlePageChange(newPage);
  }

  const handlePageReset = () => {
    setSelectedRaces([]);
    // setSelectedColumns(['id', 'race_name', 'race_owner',  'age', 'sire, dam', 'breeder_win_pct', 'breed']);
    setSearchTerm("");
    setSortConfig(null);
    setCurrentPage(1);
    navigate("/races");
  };

  function Pagination({ currentPage, totalPages, onPageChange }) {
    const pageLinks = [];
  
    // Calculate the range of Pages to display
    const startPage = Math.max(1, currentPage - 4);
    const endPage = Math.min(totalPages, startPage + 9);
  
    // Create links for the displayed Pages
    for (let i = startPage; i <= endPage; i++) {
      const isActive = i === currentPage;
      pageLinks.push(
        <button
          key={i}
          disabled={isActive}
          onClick={() => onPageChange(i)}
          style={{ margin: "0.5rem" }}
        >
          {i}
        </button>
      );
    }
  
    // Add a "Previous" button if not on the first page
    if (currentPage > 1) {
      pageLinks.unshift(
        <button
          key="prev"
          onClick={() => onPageChange(currentPage - 1)}
          style={{ margin: "0.5rem" }}
        >
          {"<"}
        </button>
      );
    }
  
    // Add a "Next" button if not on the last page
    if (currentPage < totalPages) {
      pageLinks.push(
        <button
          key="next"
          onClick={() => onPageChange(currentPage + 1)}
          style={{ margin: "0.5rem" }}
        >
          {">"}
        </button>
      );
    }
  
    return <div className="dataTable-div">{pageLinks}</div>;
  }
  
  const handleGoBack = () => {
    navigate(-1);
  };

  
  return (
    <div className="dataTable-div-wrapper" style={{ maxWidth: "100vw" }}>
      {/* Header Section */}
      <div className="dataTable-div">
        <button onClick={handleGoBack} style={{ marginTop: "2vh",marginRight:"1%" }}>
          Go Back
        </button>
        <button onClick={handlePageReset}>Reset Page</button>
        <h1 style={{ marginTop: "0" }}>Races</h1>
      </div>
  
      {/* Filters Section */}
      <div className="dataTable-div" style={{ display: "flex", flexDirection: "row", alignItems: "flex-start",marginTop:"-3%"  }}>
        {/* Race Selection Dropdown */}
        <div className="dataTable-div" style={{ marginRight: "2%", alignItems: "flex-start" }}>
          <label
            htmlFor="race-select"
            style={{
              marginLeft: "2%",
              maxWidth: "18%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            Races:
          </label>
          <select
            id="race-select"
            multiple={true}
            onChange={handleRaceSelect}
            style={{ marginLeft: "2px", maxWidth: "82%", maxHeight: "16vh" }}
          >
            {data.map((row) => (
              <option
                key={row.id}
                value={row.race_name}
              >
                {row.race_name}
              </option>
            ))}
          </select>
        </div>
  
        {/* Search Input and Search Column Dropdown */}
        <div className="dataTable-div" style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", }}>
         <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop:"6%"}}>
          <label
            htmlFor="search"
            style={{
              maxHeight: "12px",
              minWidth: "10%",
              marginLeft: "-11%",
              marginBottom:"4%",
              marginRight: "0%",
              alignSelf: "center",
            }}
          >
            Search:
          </label>
          <input
            type="text"
            id="search"
            value={searchTerm}
            onChange={handleSearch}
            style={{
              maxWidth: "20%",
              maxHeight: "4vh",
              marginRight: "2%",
              alignSelf: "center",
            }}
          />
          <label
            htmlFor="searchColumn"
            style={{
              maxHeight: "20px",
              minWidth: "20%",
              marginRight: "-2%",
              marginBottom:"4%",
              // marginTop: "0",
              alignSelf: "center",
            }}
          >
            Search Exact:
          </label>
          <select
            //defaultValue=""
            id="searchColumn"
            value={selectedSearchColumn}
            onChange={handleSearchColumnSelect}
            style={{
              maxWidth: "22%",
              maxHeight: "8vh",
              marginRight: "2%",
              alignSelf: "center",
            }}
          >
            <option value="">Specific Column</option>
            {selectedColumns.map((column) => (
              <option key={column} value={column}>
                {column}
              </option>
            ))}
          </select>
          <label
            htmlFor="Pagesize"
            style={{
              maxHeight: "20px",
              minWidth: "2%",
              marginBottom:"4%",
              marginRight: "1%",
              alignSelf: "center",
            }}
          >
            Items/Page:
          </label>
          <select
            //defaultValue
            id="Pagesize"
            value={Pagesize}
            onChange={handlePagesizeSelect}
            style={{
              maxWidth: "80px",
              maxHeight: "8vh",
              alignSelf: "center",
            }}
          >
            <option value="5">5</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
            <option value="5000">5000</option>
          </select>
        </div></div>
      </div>
      {/* Table Section */}
      <table className="data-table" style={{ cursor: "pointer" }}>
        <thead>
          <tr>
            {/* Table Header Columns */}
            {selectedColumns.map((column) => (
              <th key={column} onClick={() => handleSort(column)}>
                <FormatKey keyText={column} />
                {sortConfig &&
                  sortConfig.key === column &&
                  (sortConfig.direction === "ascending" ? "▲" : "▼")}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Table Data Rows */}
          {currentPageData.map((row) => (
            <tr
              key={row.id}
              onClick={() => navigate(`/page-race/${row.id}`)}
            >
              {selectedColumns.map((col) => (
                <td key={col}>{row[col]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {/* Pagination Section */}
      <div className="dataTable-div">
        {/* Pagination Component */}
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(totalRecords / Pagesize)}
          onPageChange={handlePageChange}
        />
        <button onClick={loadNextBatch}>Load More</button>
      </div>
    </div>
  );  
  
  }

export default Races;
