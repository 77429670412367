import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';


const apiUrl = process.env.REACT_APP_API_BASE_URL;


const MultiRaceBet = () => {
  const location = useLocation();
  const { trackName, currentRaceId } = location.state || {};
  const [tracks, setTracks] = useState([]);
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [races, setRaces] = useState([]);
  const [selectedRaces, setSelectedRaces] = useState([]);
  const [selectedHorses, setSelectedHorses] = useState({});
  const [betAmount, setBetAmount] = useState(0);

  useEffect(() => {
    if (trackName) {
      axios.get(`${apiUrl}/tracks`)
        .then(response => {
          const foundTrack = response.data.find(track => track.track_name === trackName);
          setTracks(response.data);
          setSelectedTrack(foundTrack);
        })
        .catch(error => console.error(error));
    }
  }, [trackName]);

  useEffect(() => {
    if (selectedTrack) {
      const raceIds = [];
      for (let i = 1; i <= 13; i++) {
        if (selectedTrack[`race_id${i}`]) {
          raceIds.push(selectedTrack[`race_id${i}`]);
        }
      }
      axios.get(`${apiUrl}/races?ids=${raceIds.join(',')}`)
        .then(response => setRaces(response.data))
        .catch(error => console.error(error));
    }
  }, [selectedTrack]);

  const handleRaceSelection = (race) => {
    setSelectedRaces([...selectedRaces, race]);
  };

  const handleHorseSelection = (raceId, horseId) => {
    setSelectedHorses({ ...selectedHorses, [raceId]: horseId });
  };

  const handleSubmitBet = () => {
    const betDetails = {
      track_id: selectedTrack.id,
      races: selectedRaces.map(race => ({
        race_id: race.id,
        horse_id: selectedHorses[race.id],
      })),
      bet_amount: betAmount,
    };

    axios.post(`${apiUrl}/bets`, betDetails)
      .then(response => console.log('Bet submitted:', response.data))
      .catch(error => console.error('Error submitting bet:', error));
  };

  return (
    <div>
      <h2>Multi-Race Bet</h2>
      
      {selectedTrack && (
        <table>
          <thead>
            <tr>
              <th>Track Name</th>
              <th>Location</th>
              <th>Daily Races</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{selectedTrack.track_name}</td>
              <td>{selectedTrack.location}</td>
              <td>{selectedTrack.daily_races}</td>
            </tr>
          </tbody>
        </table>
      )}

      {selectedTrack && (
        <div>
          <h3>Select Races</h3>
          {races.map(race => (
            <div key={race.id}>
              <label>
                <input 
                  type="checkbox" 
                  value={race.id} 
                  onChange={() => handleRaceSelection(race)} 
                />
                {race.race_name}
              </label>
            </div>
          ))}
        </div>
      )}

      {selectedRaces.length > 0 && (
        <div>
          <h3>Select Horses</h3>
          {selectedRaces.map(race => (
            <div key={race.id}>
              <h4>{race.race_name}</h4>
              <select onChange={(e) => handleHorseSelection(race.id, e.target.value)}>
                <option value="">--Select Horse--</option>
                {race.horses && race.horses.map(horse => (
                  <option key={horse.id} value={horse.id}>{horse.horse_name}</option>
                ))}
              </select>
            </div>
          ))}
        </div>
      )}

      <div>
        <label>Bet Amount:</label>
        <input type="number" value={betAmount} onChange={(e) => setBetAmount(e.target.value)} />
      </div>

      <button onClick={handleSubmitBet}>Submit Bet</button>
    </div>
  );
};

export default MultiRaceBet;
