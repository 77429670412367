// This is heavily commented for training Fernando and the plain English description is below the code.

// Import necessary libraries and components
import React, { useState, useEffect, useRef } from "react";
import { v4 as uuid } from "uuid"; // Import a library for generating unique IDs
import axios from "axios"; // Import a library for making HTTP requests
import { useNavigate } from "react-router-dom"; // Import a function for navigation
import { setAuthToken } from "./authUtils"; // Import a function for setting authentication tokens
import SensipassLogin from "./SensiPassLogin"; // Import a SensiPass component

// Define a constant for the AUTO LOGOUT timeout duration (20 minutes)
const TIMEOUT = 20 * 60000; // (1 sec = 1000 mili)
const apiUrl = process.env.REACT_APP_API_BASE_URL;

// Create a functional component called 'Login'
const Login = () => {
  // Define and initialize state variables using the 'useState' hook
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [message, setMessage] = useState(""); // Used for displaying messages to the user
  const [userData, setUserData] = useState([]); // Used for storing user data

  // Create a reference to manage the timeout
  const timeoutIdRef = useRef(null);

  // Access the navigation function from React Router
  const navigate = useNavigate();

  // useEffect to check if the user is already logged in
  useEffect(() => {
    // A function to check if the user is already logged in
    const checkLoggedInUser = async () => {
      // Check if a session ID exists in the session storage
      const sessionId = sessionStorage.getItem("session_id");
      
      if (sessionId) {
        try {
          // Send a GET request to the server with the session ID
          const response = await axios.get(`${apiUrl}/sessions/${sessionId}`);

          // Get session data from the response
          const session = response.data;

          // Check if the session is active and within the timeout limit
          if (
            session &&
            Date.now() - new Date(session.login_date).getTime() <= TIMEOUT &&
            session.login_status === "active"
          ) {
            // Set user ID in local storage and mark the user as logged in
            localStorage.setItem("userId", session.user_id);
            setIsLoggedIn(true);
            // Redirect to the "program-card" page
            navigate("/program-card");
          } else {
            // If not active or within the timeout, remove the session ID
            sessionStorage.removeItem("session_id");
          }
        } catch (error) {
          // Handle errors, such as a 404 response (not found)
          if (error.response && error.response.status === 404) {
            sessionStorage.removeItem("session_id");
          }
        }
      }
    };

    // Call the function to check for a logged-in user
    checkLoggedInUser();

    // Function to start the logout timer
    const startLogoutTimer = () => {
      timeoutIdRef.current = setTimeout(() => {
        // Display a message and trigger the logout function
        setMessage("You have been logged out due to inactivity");
        handleLogout();
      }, TIMEOUT);
    };

    // Start the logout timer when this effect runs
    startLogoutTimer();

    // Cleanup function to clear the timer if this effect is removed
    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, [navigate]); // Run this effect when the 'navigate' function changes

  // useEffect to fetch user data when logged in
  useEffect(() => {
    // A function to fetch user data from the server
    const fetchUserData = async () => {
      try {
        // Send a GET request to fetch user data from the server
        const response = await axios.get(`${apiUrl}/users`);
        // Extract user data from the response and store it
        const data = response.data;
        setUserData(data);
      } catch (error) {
        // Handle errors, such as network issues
        console.error("Error while fetching user data:", error);
      }
    };

    // If the user is logged in, fetch their data
    if (isLoggedIn) {
      fetchUserData();
    }
  }, [isLoggedIn]); // Run this effect when the 'isLoggedIn' state changes

  // useEffect to track user activity
  useEffect(() => {
    // Define functions to handle different types of user activity
    const handleDocumentClick = () => {
      handleUserActivity();
    };

    const handleKeyPress = () => {
      handleUserActivity();
    };

    const handleMouseMove = () => {
      handleUserActivity();
    };

    // Add event listeners to track user activity
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("keypress", handleKeyPress);
    document.addEventListener("mousemove", handleMouseMove);

    // Cleanup function to remove event listeners if this effect is removed
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.removeEventListener("keypress", handleKeyPress);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []); // Run this effect once when the component mounts

  // Function to handle user login
  const handleLogin = async () => {
    clearTimeout(timeoutIdRef.current);
    try {
      // Send a GET request to fetch user data from the server
      const response = await axios.get(`${apiUrl}/users`);
      // Extract user data from the response
      const data = response.data;
      // Find a user with the entered email and password
      const user = data.find(
        (user) => user.email === email && user.password === password
      );

      if (user) {
        // Display a success message
        sessionStorage.setItem("pUuid", user.uuid)
        sessionStorage.setItem("userEmail", user.email)

        setMessage("Login successful!");

        // Logout the current user if there is an existing session
        const existingSessionId = sessionStorage.getItem("session_id");
        if (existingSessionId) {
          try {
            // Send a PUT request to update the existing session
            await axios.put(
              `${apiUrl}/sessions/${existingSessionId}`,
              { logout_date: new Date(), login_status: "inactive" }
            );
          } catch (error) {
            // Handle errors, such as network issues
            console.error("Error while updating existing session:", error);
          }
        }
        // Create a new session for the current user
        const sessionId = uuid();
        const loginDate = new Date();

        const sessionData = {
          user_id: user.id,
          email: user.email,
          login_date: loginDate.toISOString(),
          logout_date: null,
          login_status: "active",
          session_id: sessionId,
        };

        try {
          // Send a POST request to create a new session
          await axios.post(`${apiUrl}/sessions`, sessionData, {
            headers: { "Content-Type": "application/json" },
          });

          // Store session and user ID in storage
          sessionStorage.setItem("session_id", sessionId);
          localStorage.setItem("userId", user.id);

          // Store an authentication token in local storage
          localStorage.setItem('token', sessionId);

          // Set the authentication token for future requests
          setAuthToken(sessionId);
          
          // Mark the user as logged in and navigate to the "program-card" page
          setIsLoggedIn(true);
          navigate("/program-card");

          // Start the logout timer for inactivity
          timeoutIdRef.current = setTimeout(() => {
            setMessage("You have been logged out due to inactivity");
            handleLogout();
          }, TIMEOUT);
        } catch (error) {
          // Handle errors, such as network issues
          console.error("Error while creating a new session:", error);
        }
      } else {
        // Display an error message for incorrect login details
        setMessage("Incorrect email or password. Please try again.");
      }
    } catch (error) {
      // Handle errors, such as network issues
      console.error("Error during login:", error);
      setMessage("An error occurred during login. Please try again.");
    }
  };

  // Function to handle user logout
  const handleLogout = async () => {
    // Get the session ID from session storage
    const sessionId = sessionStorage.getItem("session_id");
    if (sessionId) {
      try {
        // Send a PUT request to update the session status to "inactive"
        await axios.put(`${apiUrl}/sessions/${sessionId}`, {
          logout_date: new Date(),
          login_status: "inactive",
        });

        // Clear the timeout and remove session-related data
        clearTimeout(timeoutIdRef.current);
        sessionStorage.removeItem("session_id");

        // Remove the authentication token from local storage
        localStorage.removeItem('token');

        // Remove user-related data from local storage
        localStorage.removeItem("userId");

        // Mark the user as logged out and display a message
        setIsLoggedIn(false);
        setMessage("You have been logged out due to inactivity");
        
        // Navigate to the "home" page
        navigate("/home");
      } catch (error) {
        // Handle errors, such as network issues
        console.error("Error while updating session during logout:", error);
      }
    }
  };

  // Function to handle user activity and reset the logout timer
  const handleUserActivity = () => {
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      // Display a message and trigger the logout function
      setMessage("You have been logged out due to inactivity");
      handleLogout();
    }, TIMEOUT);
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    if (isLoggedIn) {
      // If already logged in, navigate to the "program-card" page
      navigate("/program-card");
    } else {
      // If not logged in, initiate the login process
      handleLogin();
    }
  };

  // Render the login form and messages
  return (
    <div style={{marginTop:'-2%',marginLeft:'2%',}}>
      <h1>Please Login</h1>
      {message && (
        <h3>
          <p style={{ textAlign: "center" }}>{message}</p>
        </h3>
      )}
      <form onSubmit={handleSubmit} style={{width:'32%'}}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            required
          />
        </div>
        <button type="submit" style={{display:'block', marginLeft:'auto',marginRight:'auto'}}>{isLoggedIn ? "Logged In" : "Login"}</button>
        <div></div>
            </form>
      <SensipassLogin domainName={`${apiUrl}`}/>

    </div>
    
  );
};

// Export the 'Login' component
export default Login;

