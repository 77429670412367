import React from 'react';
const apiUrl = process.env.REACT_APP_API_BASE_URL;

function FormatKey(props) {
  function formatKey(key) {
    const words = key.split('_');
    const excludedWords = ['id',]; // Add words you want to exclude here
    const formattedWords = words.map(word => {
      const formattedWord = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      return excludedWords.includes(formattedWord.toLowerCase()) ? '' : formattedWord;
    });
    return formattedWords.filter(Boolean).join(' ');
  }

  const { keyText } = props;
  const formattedKey = formatKey(keyText);

  return (
    <span>{formattedKey}</span>
  );
}

export default FormatKey;
