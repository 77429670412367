

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';


const apiUrl = process.env.REACT_APP_API_BASE_URL;


function Record() {
  const params = useParams();
  const id = params.id;
  // console.log(params);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [fieldsToShow, setFieldsToShow] = useState(14);
  const [columnsToShow, setColumnsToShow] = useState(2);

  useEffect(() => {
    axios.get(`${apiUrl}/horses/${id}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        setError(error);
      });
  }, [id]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  const fieldKeys = Object.keys(data).slice(0, fieldsToShow);
  const numColumns = Math.ceil(fieldKeys.length / columnsToShow);
  const rows = Array.from({ length: numColumns }, (_, i) => (
    <tr key={i}>
      {fieldKeys.slice(i * columnsToShow, (i + 1) * columnsToShow).map((key, j) => (
        <React.Fragment key={j}>
          <td>{key.replace('_', ' ')}</td>
          <td>
            <input type="text" value={data[key] ?? ''} readOnly />
            </td>
        </React.Fragment>
      ))}
    </tr>
  ));

  const handleFieldsToShowChange = event => {
    setFieldsToShow(parseInt(event.target.value));
  };

  const handleColumnsToShowChange = event => {
    setColumnsToShow(parseInt(event.target.value));
  };

  return (
    <div className="container" style={{marginTop:'3vh',marginLeft:'1vw'}} >   <div className="container">
    <button onClick={() => window.history.back()} className="back-button">Back</button>

  </div>
      
      <form  style={{"maxwidth":"90%", "width":"auto"}}> 
       
        <div className="row" >
          <div className="col" >
            <label htmlFor="fields_to_show" ># Fields:</label>
            <input
              type="number"
              id="fields_to_show"
              name="fields_to_show"
              min="1"
              max={Object.keys(data).length}
              value={fieldsToShow}
              onChange={handleFieldsToShowChange}
            />
          </div>
          <div className="col" >
            <label htmlFor="columns_to_show"># Columns:</label>
            <input
              type="number"
              id="columns_to_show"
              name="columns_to_show"
              min="1"
              max={Math.ceil(fieldKeys.length)}
              value={columnsToShow}
              onChange={handleColumnsToShowChange}
            />
          </div>
        </div>
        <table >
          <tbody >  
            {rows}
          </tbody>
        </table>
      </form></div>
   
  );
}

export default Record;
