
// DataProvider.js TEST FOR RECECONTEXT PROVIDER
import React, { useContext, useEffect } from 'react';
import { RaceContext } from '../Pages/RaceContext';


const apiUrl = process.env.REACT_APP_API_BASE_URL;


const DataProvider = () => {
  const { setRace, setCurrentRaceId, setNavigateData } = useContext(RaceContext);

  useEffect(() => {
    // Simulating some data being set into the RaceContext
    const raceData = { id: 123, name: 'Sample Race' };
    const currentRaceId = 123;
    const navigateData = { raceId: 123, horseIds: '1,2,3', horseNames: 'Horse1,Horse2,Horse3', numHorses: 3 };
    setRace(raceData);
    setCurrentRaceId(currentRaceId);
    setNavigateData(navigateData);
  }, [setRace, setCurrentRaceId, setNavigateData]);

  return (
    <div>
      <h2>Data Provider</h2>
      <p>Data has been set into the RaceContext.</p>
    </div>
  );
};

export default DataProvider;
