
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { v4 as uuid } from 'uuid';

// This is a local server url not sensipass.  This get sent with the UUID to identify where to return data
const apiUrl = process.env.REACT_APP_API_BASE_URL;
const domainName = ' ${apiUrl}';


function Register() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    uuid: uuid(), // Automatically generate and include the UUID
  });

  const navigate = useNavigate();

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    const { username, email, } = formData; // Extract username and email

    try {
      // Send a POST request to the server with the formData
      const response = await axios.post(`${domainName}/users`, formData); // Use domainName variable
      console.log(response.data);

      // Store the UUID and email in session storage
      sessionStorage.setItem('pUuid', formData.pUuid);
      sessionStorage.setItem('userEmail', formData.email);

      setFormData({
        username: '',
        email: '',
        password: '',
        pUuid: uuid(), // Generate a new UUID for the next registration
      });

      // Redirect to SensiPass registration page with username and email as query parameters
      navigate(`/sensi-pass-register?pUuid=${formData.pUuid}&email=${email}&domainName=${domainName}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    // Generate a new UUID when the component mounts
    setFormData((prevFormData) => ({
      ...prevFormData,
      pUuid: uuid(),
    }));
  }, []); // Empty dependency array ensures this effect runs only once on mount


  return (
    <>
      <h1>Sign Up</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </div>
        {/* <div>
          <label htmlFor="uuid">UUID:</label>
          <input
            type="text"
            id="uuid"
            name="uuid"
            value={formData.uuid}
            readOnly
          />
        </div> */}
        <button type="submit">Submit</button>
      </form>
    </>
  );
}

export default Register;



// DETAILED description of functionality
////////////////////////////////////////////////////////////////////////////

// **Step 1: Initialization**
// - When you open this page, it's like starting a blank slate.
// - The component sets up a space in memory to remember certain information.
// - It's like preparing an empty form for you to fill out.

// **Step 2: Filling Out the Form**
// - You see a form with fields for your username, email, and password.
// - You type in your information into these fields, just like filling out a paper form.

// **Step 3: Submitting the Form**
// - When you're done filling out the form, you click the "Submit" button.
// - Imagine this as handing in your paper form to someone.

// **Step 4: Handling the Submission**
// - The component takes the information you entered and sends it to a special address on the internet ( ${apiUrl}/users).
// - It's similar to mailing your paper form to a specific address.

// **Step 5: Waiting for a Response**
// - After sending the information, the component waits for a response from the internet address.
// - It's like waiting for a reply after sending a letter.

// **Step 6: Getting a Response**
// - If everything goes well, you'll get a response back from that internet address.
// - The component then logs (records) the response data, which could be a confirmation or some information from the server.

// **Step 7: Optionally Clearing the Form**
// - If everything is successful, you have the option to clear the form fields.
// - This is like erasing the information you filled in on your paper form after it's been successfully processed.

// **Step 8: Redirecting to Another Page**
// - After submitting the form successfully, you're redirected to a different page (e.g., '/login').
// - It's like being directed to another room or place after completing a task.

// **Step 9: Handling Errors**
// - If there's a problem during the submission, like an internet connection issue or server trouble, an error message is logged.
// - This is like receiving a notification that your letter couldn't be delivered.

// **Step 10: Input Field Changes**
// - While you're filling out the form, the component watches for any changes you make in the fields (like typing a username or password).
// - When you type, it remembers what you're typing.

// **Step 11: Updating State with Input Changes**
// - As you type, the component updates its memory with what you type. This memory is called "state."
// - It's like the component jotting down what you're typing in real-time.

// **Step 12: Required Fields**
// - The component also makes sure that you fill out all the required fields (username, email, and password).
// - It's like having a note that says "Don't forget to fill in all the blanks on the paper form."

// **Step 13: Displaying the Form**
// - Finally, everything is displayed on the screen.
// - You see the form, input fields, labels, and the "Submit" button.

// So, this component lets you fill out a form, sends that form to the internet, waits for a response, and then either logs the response or handles errors. 
// It also watches what you type and makes sure you don't leave any important fields empty.