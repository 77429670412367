// Summary description, this component handles the logout process, including sending a request to the server, 
// showing appropriate messages to the user, and performing cleanup actions. It ensures a smooth transition for 
// the user from a logged-in state to a logged-out state.


import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Logout = () => {
  const navigate = useNavigate();
  const [showLogoutMessage, setShowLogoutMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    let isMounted = true;

    // Step 1: Define a function to handle logout
    const handleLogout = async () => {
      // Step 2: Get the session ID and user ID from session storage and local storage
      const sessionId = sessionStorage.getItem("session_id");
      const userId = localStorage.getItem("userId");

      // Step 3: Check if both session ID and user ID are available
      if (sessionId && userId) {
        try {
          // Step 4: Send a request to the server to log out the user
          await axios.post(`${apiUrl}/logout`, {
            user_id: userId,
            session_id: sessionId
          });

          // Step 5: If successful, show a logout message and navigate to the home page
          if (isMounted) {
            setShowLogoutMessage(true);
            // Step 6: Wait for a moment (1 seconds) to display the message
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Step 7: Remove session and user information, navigate to the home page, and refresh the page
            sessionStorage.removeItem("session_id");
            localStorage.removeItem("userId");
            localStorage.removeItem("token");
            navigate("/home");
            // Clear local storage
            localStorage.clear();
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        // Step 8: Handle the case where session ID or user ID is missing
        if (isMounted) {
          setErrorMessage("Session ID or user ID is missing");       
          navigate("/login");
        }
      }
    };

    // Step 9: Call the logout function when the component mounts
    handleLogout();

    // Step 10: Cleanup logic when the component is unmounted
    return () => {
      isMounted = false;
      setShowLogoutMessage(false);
      setErrorMessage("");
    };
  }, [navigate]);

  // Step 11: Render a message to inform the user about the logout process and any potential errors
  return (
    <div>
      {showLogoutMessage && (
        // Step 12: Show a success message when logging out
        <div
          style={{
            position: "fixed",
            top: "200px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#f8f8f8",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
            fontSize: "18pt",
            zIndex: "9999"
          }}
        >
          You are now being logged out.
        </div>
      )}
      {errorMessage && (
        // Step 13: Show an error message if there's an issue with the logout process
        <div
          style={{
            position: "fixed",
            top: "250px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#f8f8f8",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
            fontSize: "14pt",
            zIndex: "9999"
          }}
        >
          Error: {errorMessage}
        </div>
      )}
    </div>
  );
};

export default Logout;
