import React from "react";
import "../Styles/Home.css";
import horseImage from "../Pages/Images/horse5.jpg";
import FeaturedItems from "../Modules/FeaturedItems";


const apiUrl = process.env.REACT_APP_API_BASE_URL;



function Home() {
  return (
    <>
    <figure
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "2vw",
          marginRight: "2vw",
          maxWidth: "25%",
          marginTop: "-1%",
        }}
      >
        <img src={horseImage} alt="horserace" style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "1vw",
          marginRight: "1vw",
          maxWidth: "55%",
          marginTop: "12vh",
        }} />
      </figure>
    <div 
    className="home-wrapper" style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "25vw",
          maxWidth: "50vw",
          marginTop: "-30vh",
        }}  
        >
      <h1 style={{textAlign:'center', marginTop:'-4%' }}>Welcome to HotPonies</h1> <h2 style={{textAlign:'center',marginTop:'-2%'}}>Horse Racing Game and Analysis </h2> 
      <p>
        We're excited to bring you the ultimate horse racing experience online.
      </p>
      <p>
        With our application, you'll be able to watch live simulation horse
        races, place bets, purchase, sell, own and care for your horses, as well as view
        races and results in real-time.
      </p>
      <p>There will also be fun features such as, if one of the races featured performers is the winner in which you're participating, a special bonus or feature will be applied to your account.</p>
      {/* <p>
        You will be able to review all the trends and statistics of horses,
        jockeys, trainers, and tracks to make the best informed bets on actual races,
        and decide where and how to improve your horses chances of winning.
      </p>
      <p>For gaming you can purchase items to improve your stable conditions, track management, 
        and other details about the entire racing environment. You can even determine
        the care and medical treatment of your horse(s).  How else will you beat the other players?
      </p>
      <p>
        Our application is designed to be user-friendly, so you can easily
        navigate through the different features and get the most out of your
        experience. Other players will want to purchase your horse at auction if it has a winning record.
      </p>
      <p>
        Whether you're a seasoned horse racing professional, enthusiast or new to the sport,
        we have something for everyone.
      </p>
      <p>If there are past races you'd like to see, or you're wondering if Seabiscuit
        would beat Man O' War, you can configure the race of your desires to see what 
        happens.
      </p>
      <p>And last but not least, you can learn and advocate for the safe and humane treatment of horses.
        Before, during and after races, it is very important that horses be treated with care, not just in the game, 
        but also in real  life.  So please leave comments for horse owners, jockeys, and trainers to treat their animals well.
      </p>
      <p>
        So what are you waiting for? Sign up today and start your journey into
        the world of horse racing with our cutting-edge application that was uses Artifical Intelligence 
        to bring you the best Horse Racing Experience anywhere.
      </p> */}
      <div style={{marginLeft:'68%', width:'50%'}}><FeaturedItems/></div>
      <p> PLEASE STAY TUNED.....</p>
      <p className="Home-div" style={{fontSize:'14px', textAlign: 'right',  width: '60vw', line: '8px'}}>Racing Animation by: Sebastian Alexander Ascarruz Rosas 
      <br/> email:  sebastianascarruz6@gmail.com <br/>All Graphics by AI </p>
      <span>&copy; 2023 Hotponies.com All rights reserved.</span>
    </div>
    </>
  );
}

export default Home;
