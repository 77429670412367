import React, { useEffect, useState } from "react";

const basePath = process.env.REACT_APP_API_BASE_URL; // Backend API base URL
const imagesPath = process.env.REACT_APP_IMAGES_PATH; // Images path from env

const PreLoadedImages = ({ images = [], onProgress = null, retryAttempts = 1, timeoutMs = 5000, children }) => {
  const [loadedImages, setLoadedImages] = useState([]);
  const [loadingErrors, setLoadingErrors] = useState([]);

  useEffect(() => {
    const preloadImages = async () => {
      const totalImages = images.length;

      const loadImageWithRetry = (src, retries) =>
        new Promise((resolve, reject) => {
          const attemptLoad = (attempt) => {
            const img = new Image();
            const timer = setTimeout(() => {
              if (attempt < retries) {
                attemptLoad(attempt + 1);
              } else {
                reject(new Error(`Failed after ${retries} attempts: ${src}`));
              }
            }, timeoutMs);

            img.onload = () => {
              clearTimeout(timer);
              resolve(img);
            };

            img.onerror = () => {
              clearTimeout(timer);
              if (attempt < retries) {
                attemptLoad(attempt + 1);
              } else {
                reject(new Error(`Failed after ${retries} attempts: ${src}`));
              }
            };

            img.src = `${basePath}${imagesPath}/${src}`; // Combine API and images path
          };

          attemptLoad(0);
        });

      const results = await Promise.allSettled(
        images.map((src) => loadImageWithRetry(src, retryAttempts))
      );

      const successful = results.filter((r) => r.status === "fulfilled").map((r) => r.value);
      const failed = results.filter((r) => r.status === "rejected").map((r) => r.reason);

      setLoadedImages(successful);
      setLoadingErrors(failed);

      if (onProgress) {
        onProgress({ loaded: successful.length, total: totalImages });
      }
    };

    preloadImages();
  }, [images, retryAttempts, timeoutMs, onProgress]);

  return <>{children({ loadedImages, loadingErrors })}</>;
};

export default PreLoadedImages;
