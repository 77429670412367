
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SensiPassInstructions from './SensiPassInstructions';// QR Code .
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const domainName = 'hotponies.com';  

function SensiPassRegister() {
  const navigate = useNavigate();
  const location = useLocation(); // Access the current location

  // Function to handle registration with SensiPass
  const handleRegisterSensiPass = () => {
    // Retrieve UUID and email from session storage - must be registering or be logged
    const pUuid = sessionStorage.getItem('pUuid');
    const userEmail = sessionStorage.getItem('userEmail');

    // Remove session storage variables
    sessionStorage.removeItem('pUuid');
    sessionStorage.removeItem('userEmail');

    console.log('SESSIONSTORAGE VARIABLES', pUuid, userEmail);

    // Construct the URL with query parameters for UUID, email, and domainName
    const url = `http://ec2-54-78-161-29.eu-west-1.compute.amazonaws.com/#/login?pUuid=${pUuid}&pemail=${userEmail}&domainName=${domainName}`;


    // Redirect to the constructed URL
    window.location.href = url;
  };

  // Function to handle registration later
  const handleMaybeLater = () => {
    // Check if the user is already logged in by verifying the presence of userUuid and userEmail
    const pUuid = sessionStorage.getItem('pUuid');
    const userEmail = sessionStorage.getItem('userEmail');

    if (pUuid && userEmail) {
      // Redirect the user back to the previous page they came from
      navigate(-1);
    } else {
      // If not logged in, navigate to the '/login' route
      navigate('/login');
    }
  };


  return (
    <div style={{textAlign: 'center' }}>
     <button onClick={handleMaybeLater} style={{display:'block', marginLeft:'1%', marginTop: '2vh',backgroundColor: '#ccc', color: '#333', border: 'none', borderRadius: '4px', padding: '10px 20px', fontSize: '18px', cursor: 'pointer' }}>Maybe Later</button>
      {/* <button onClick={handleRegisterSensiPass} style={{display:'block', marginLeft:'1%', border: 'none', borderRadius: '4px', padding: '10px 20px', fontSize: '18px', cursor: 'pointer' }}>Register SensiPass</button>  */}

      <h1 style={{marginTop:'-4%'}}>SENSIPASS:  Simple - Secure</h1>
      {/* <h2 style={{ marginTop: "-1.5%" }}>Smile, Swipe, Done</h2> */}
      <div>
        <h2 style={{marginTop:'-1.5%'}}>No passwords to remember... or forget!  (TRUE 3FA)</h2>
        <p/> 
        <p/>       <SensiPassInstructions/>
        {/* <button onClick={handleRegisterSensiPass} style={{ margin: '1%',  border: 'none', borderRadius: '4px', padding: '10px 20px', fontSize: '18px', cursor: 'pointer' }}>Register SensiPass</button>
        <button onClick={handleMaybeLater} style={{ backgroundColor: '#ccc', color: '#333', border: 'none', borderRadius: '4px', padding: '10px 20px', fontSize: '18px', cursor: 'pointer' }}>Maybe Later</button> */}
      </div>
    </div>
  );
}

export default SensiPassRegister;

  