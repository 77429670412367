
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { config } from "../../config";


const apiUrl = process.env.REACT_APP_API_BASE_URL;


const RaceResultsHandler = ({
  currentRaceId,
  horseNames,
  numHorses,
  horsePositions,
  wirePosition,
  winnerDeclared,
  winnerNumber,
  winnerName,
  raceDuration,
  finishOrder,
  horseTimes
}) => {
  const [raceFinished, setRaceFinished] = useState(false);
  const dataSubmitted = useRef(false);

  // Function to fetch horse IDs and additional details (owner, jockey, trainer) from the backend based on horse names
  const fetchHorseDetails = async (horseNames) => {
    try {
      // Send a request for each horse name to get its corresponding details
      const responses = await Promise.all(
        horseNames.map(name => axios.get(`${config.backendUrl}/query/horses/horse_name/${name}`))
      );
      // Extract the IDs and additional details from the responses
      const details = responses.map(response => {
        if (response.data && response.data.length > 0) {
          const horseData = response.data[0];
          return {
            id: horseData.id,
            owner: horseData.owner_id,
            jockey: horseData.jockey_id,
            trainer: horseData.trainer_id
          }; // Ensure response structure is correct
        }
        return undefined;
      });
      return details;
    } catch (error) {
      console.error("Error fetching horse details:", error);
      return [];
    }
  };

  const submitRaceResults = async (horseDetails) => {
    if (!winnerDeclared || finishOrder.length < numHorses || dataSubmitted.current) {
      return;
    }

    try {
      const wireMinusHorsePositions = horsePositions.map(
        (horsePosition) => wirePosition - horsePosition.position
      );

      // Reorder horse names and details to match the finish order
      const reorderedHorseNames = finishOrder.map(order => horseNames[order.index]);
      const reorderedHorseDetails = finishOrder.map(order => horseDetails[order.index]);

      const raceResultsData = {
        race_id: currentRaceId,
        horse_ids: reorderedHorseDetails.map(detail => detail.id),
        horse_names: reorderedHorseNames,
        num_horses: numHorses,
        winner_number: finishOrder[0]?.number,
        race_positions: finishOrder.map((horse) => ({
          id: horse.id,
          position: horse.position,
        })),
        horse_positions: horsePositions.map((horse) => horse.position),
        wire_position: wirePosition,
        wire_horse_positions: wireMinusHorsePositions,
        winner_name: finishOrder[0]?.name,
        race_duration: raceDuration,
        finish_order: finishOrder.map(horse => horse.number),
        first: finishOrder[0]?.number || null,
        second: finishOrder[1]?.number || null,
        third: finishOrder[2]?.number || null,
        fourth: finishOrder[3]?.number || null,
        horse_times: horseTimes,
        owner: reorderedHorseDetails.map(detail => detail.owner),
        jockey: reorderedHorseDetails.map(detail => detail.jockey),
        trainer: reorderedHorseDetails.map(detail => detail.trainer),
      };

      console.log('Submitting raceResultsData:', raceResultsData);

      // Submit the race results data to the backend
      await axios.post(`${config.backendUrl}/race_results`, raceResultsData);
      setRaceFinished(true);
      dataSubmitted.current = true; // Mark data as submitted
    } catch (error) {
      console.error("Error capturing race results:", error);
    }
  };

  useEffect(() => {
    if (horsePositions.length > 0 && winnerDeclared && finishOrder.length >= numHorses && raceDuration != null && !dataSubmitted.current) {
      fetchHorseDetails(horseNames).then(fetchedHorseDetails => {
        if (fetchedHorseDetails.includes(undefined)) {
          console.error('Some horse details could not be fetched. Aborting submission.');
          return;
        }
        submitRaceResults(fetchedHorseDetails);
        dataSubmitted.current = true; // Ensure this flag is set to prevent further submissions
      });
    }
  }, [horsePositions, winnerDeclared, finishOrder, numHorses, raceDuration]);

  return (
    <div>
      {raceFinished ? <p>.</p> : <p>.</p>}
    </div>
  );
};

export default RaceResultsHandler;
