
import React, { useContext, useEffect, useState } from "react";
import { FreeContext } from "./FreeContext";
import FreeRandomData from "../Modules/FreeRandomData";


const apiUrl = process.env.REACT_APP_API_BASE_URL;


const FreeToteBoards = () => {
  const { randomData } = useContext(FreeContext);

  const { horses, jockeys, trainers, owners } = randomData;
  console.log('horses, jockeys, trainers, owners:', horses, jockeys, trainers, owners, randomData)

  useEffect(() => {
    // Save the horse and jockey names to session storage
    const horseNames = horses.map((horse) => horse.horse_name);
    const jockeyNames = jockeys.map((jockey) => jockey.jockey_name);
    sessionStorage.setItem('horses_name', JSON.stringify(horseNames));
    sessionStorage.setItem('jockeys_name', JSON.stringify(jockeyNames));
  }, [horses, jockeys]);

  // Check if the data is loaded from session storage before rendering
  const isDataLoaded =
    horses.length > 0 &&
    jockeys.length > 0 &&
    trainers.length > 0 &&
    owners.length > 0;

  // Render a loading message if the required data is still loading or randomData is not available
  if (!isDataLoaded) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ marginTop: '10%' }}>
      <h2 id="freetoteboard-title">FreeToteBoard</h2>
      {/* Render the data if it is available */}
      <div>
        <h3>Horses:</h3>
        <ul>
          {horses.map((horse) => (
            <li key={horse.id}>{horse.horse_name}</li>
          ))}
        </ul>
      </div>
      <div>
        <h3>Jockeys:</h3>
        <ul>
          {jockeys.map((jockey) => (
            <li key={jockey.id}>{jockey.jockey_name}</li>
          ))}
        </ul>
      </div>
      <div>
        <h3>Trainers:</h3>
        <ul>
          {trainers.map((trainer) => (
            <li key={trainer.id}>{trainer.trainer_name}</li>
          ))}
        </ul>
      </div>
      <div>
        <h3>Owners:</h3>
        <ul>
          {owners.map((owner) => (
            <li key={owner.id}>{owner.owner_name}</li>
          ))}
        </ul>
      </div>
      {/* <FreeRandomData/> */}
    </div>
  );
};

export default FreeToteBoards;


