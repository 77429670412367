import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import "./index.css";
import { RaceProvider } from "./Components/Pages/RaceContext";
import AuthProvider from "./Components/Modules/Auth/AuthContext";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

if (!apiUrl) {
  throw new Error("API URL is not defined. Please set REACT_APP_API_BASE_URL in the .env file.");
}

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <RaceProvider>
        <App />
      </RaceProvider>
    </AuthProvider>
  </QueryClientProvider>
);
