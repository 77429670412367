// src/config.js

const ENV = process.env.NODE_ENV || 'development'; // Default to 'development' if NODE_ENV is not set

export const config = {
  // Backend API URL
  backendUrl: 
    ENV === 'production' 
      ? process.env.REACT_APP_BACKEND_URL_PROD 
      : process.env.REACT_APP_BACKEND_URL_DEV || 'http://localhost:3005',

  // Static File Paths
  paths: {
    images: 
      ENV === 'production' 
        ? '/www/html/hotponies/public/images'
        : '/public/images', // Local development default
    pics: 
      ENV === 'production' 
        ? '/www/html/hotponies/public/pics'
        : '/public/pics', 
    documents: 
      ENV === 'production' 
        ? '/www/html/hotponies/public/documents'
        : '/public/documents',
  },

  // Database Configuration
  db: {
    user: process.env.DB_USER || 'defaultUser',
    password: process.env.DB_PASSWORD || 'defaultPassword',
    database: process.env.DB_DATABASE || 'defaultDatabase',
    port: parseInt(process.env.DB_PORT, 10) || 5432,
    host: process.env.DB_HOST || 'localhost',
  },

  // Secrets
  jwtSecret: process.env.JWT_SECRET || 'defaultJwtSecret',
  sessionSecret: process.env.SESSION_SECRET || 'defaultSessionSecret',
};
