// import React, { useContext } from "react";
// import { FreeContext } from "../Pages/FreeContext";
// import FreeRandomData from "./FreeRandomData";

// function FeaturedItems() {
//   const { randomData } = useContext(FreeContext);

//   return (
//     <>
//       {/* Call the FreeRandomData component with count=1 */}
//       <FreeRandomData count={1} />

//       <div className="featured-items">
//         <h2>Featured Performers</h2>
//         <ul style={{marginLeft:'-6%'}}>
//           <li >
//             <strong>Horse:</strong>{" "}
//             {randomData.horses ? randomData.horses[0].horse_name : "Loading..."}
//           </li>
//           <li>
//             <strong>Jockey:</strong>{" "}
//             {randomData.jockeys ? randomData.jockeys[0].jockey_name : "Loading..."}
//           </li>
//           <li>
//             <strong>Trainer:</strong>{" "}
//             {randomData.trainers ? randomData.trainers[0].trainer_name : "Loading..."}
//           </li>
//           <li>
//             <strong>Owner:</strong>{" "}
//             {randomData.owners ? randomData.owners[0].owner_name : "Loading..."}
//           </li>
//         </ul>
//       </div>
//     </>
//   );
// }

// export default FeaturedItems;



import React, { useContext, useEffect, useState } from "react";
import { FreeContext } from "../Pages/FreeContext";
import FreeRandomData from "./FreeRandomData";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

function FeaturedItems() {
  const { randomData } = useContext(FreeContext);
  const [featuredData, setFeaturedData] = useState(null);
  const [reloadTimer, setReloadTimer] = useState(false);

  // Load the featured data from local storage on the initial mount
  useEffect(() => {
    const storedFeaturedData = localStorage.getItem("featuredData");
    if (storedFeaturedData) {
      setFeaturedData(JSON.parse(storedFeaturedData));
    }
  }, []);

  useEffect(() => {
    // Check if randomData has changed and set the featured data accordingly
    if (randomData && randomData.horses && randomData.horses[0]) {
      const newFeaturedData = {
        horse: randomData.horses[0].horse_name,
        jockey: randomData.jockeys ? randomData.jockeys[0].jockey_name : "Loading...",
        trainer: randomData.trainers ? randomData.trainers[0].trainer_name : "Loading...",
        owner: randomData.owners ? randomData.owners[0].owner_name : "Loading...",
      };

      // Store the featured data in local storage
      localStorage.setItem("featuredData", JSON.stringify(newFeaturedData));

      setFeaturedData(newFeaturedData);
    }
  }, [randomData]);

  useEffect(() => {
    // Set the timer to reload after one hour (3600000 milliseconds)
    const timer = setTimeout(() => {
      setReloadTimer(true);
    }, 3600000);

    // Clear the timer when the component unmounts or when the reloadTimer state is true
    return () => clearTimeout(timer);
  }, [reloadTimer]);

  return (
    <>
      {!reloadTimer && <FreeRandomData count={1} />}

      <div className="featured-items">
        <h2>Featured Performers</h2>
        <ul style={{ marginLeft: "-6%" }}>
          <li>
            <strong>Horse:</strong> {featuredData ? featuredData.horse : "Loading..."}
          </li>
          <li>
            <strong>Jockey:</strong> {featuredData ? featuredData.jockey : "Loading..."}
          </li>
          <li>
            <strong>Trainer:</strong> {featuredData ? featuredData.trainer : "Loading..."}
          </li>
          <li>
            <strong>Owner:</strong> {featuredData ? featuredData.owner : "Loading..."}
          </li>
        </ul>
      </div>
    </>
  );
}

export default FeaturedItems;
