import React from 'react';
const apiUrl = process.env.REACT_APP_API_BASE_URL;

function Breeding(props) {
    return (
        <div>
            Breeding
        </div>
    );
}

export default Breeding;