import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { RaceContext } from "../Pages/RaceContext";
import { useNavigate } from "react-router-dom";
import Clock from "../Modules/MathTime/Clock";
import horseImage from "../Pages/Images/horse3.jpg";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Bets = () => {
  const {
    currentRaceId,
    trackName,
    selectedHorseIds,
    selectedHorseNames,
    bets,
    setBets,
    selectedHorses,
    setSelectedHorses,
    trackId,
  } = useContext(RaceContext);

  const [selectedBet, setSelectedBet] = useState("Win");
  const [selectedAmount, setSelectedAmount] = useState(2);
  const [cachedBetTypes, setCachedBetTypes] = useState([]);
  const [betTypeDescription, setBetTypeDescription] = useState("");
  const [showDescription, setShowDescription] = useState(true);
  const [maxHorsesSelectable, setMaxHorsesSelectable] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [horseOrder, setHorseOrder] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Race ID in Bets component:", currentRaceId);
    console.log("Track Name in Bets component:", trackName);
    console.log("Track ID in Bets component:", trackId);
    if (!currentRaceId) {
      console.error("No raceId available for the bet submission.");
    }
  }, [currentRaceId, trackName, trackId]);

  const formatCurrency = (number) => {
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  // Fetch available bet types
  useEffect(() => {
    const fetchBetTypes = async () => {
      try {
        const response = await axios.get(`${apiUrl}/bet_types`);
        setCachedBetTypes(response.data);
        console.log("Fetched Bet Types:", response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBetTypes();
  }, []);

  // Update bet description based on selected bet type
  useEffect(() => {
    const defaultBetType = cachedBetTypes.find((betType) => betType.bet_type === selectedBet);
    if (defaultBetType && showDescription) {
      setBetTypeDescription(defaultBetType.description);
    }
  }, [selectedBet, showDescription, cachedBetTypes]);

  const handleCheckboxChange = () => {
    setShowDescription(!showDescription);
  };

  const handleBetChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedBet(selectedValue);

    const selectedBetType = cachedBetTypes.find((betType) => betType.bet_type === selectedValue);
    if (selectedBetType) {
      setBetTypeDescription(selectedBetType.description);
      setMaxHorsesSelectable(getHorseSelectionLimit(selectedBetType.bet_type));
      setHorseOrder(Array(getHorseSelectionLimit(selectedBetType.bet_type)).fill(""));
    } else {
      setBetTypeDescription("Description not available for this bet type");
    }
  };

  const getHorseSelectionLimit = (betType) => {
    const limits = {
      Win: 1,
      Place: 1,
      Show: 1,
      Trifecta: 3,
      Superfecta: 4,
      Exacta: 2,
      Quinella: 2,
      "Pick 3": 3,
      "Pick 4": 4,
      "Pick 5": 5,
      // Other bet types can be added here
    };
    return limits[betType] || 0;
  };

  const handleAmountChange = (event) => {
    setSelectedAmount(event.target.value);
  };

  const handleHorseChange = (event) => {
    const selectedHorsesArray = Array.from(event.target.selectedOptions, (option) => ({
      label: option.label,
      value: option.value,
    }));
    const limitedSelectedHorses = selectedHorsesArray.slice(0, maxHorsesSelectable);
    setSelectedHorses(limitedSelectedHorses);
    setHorseOrder(Array(limitedSelectedHorses.length).fill(""));
  };

  const handleOrderChange = (index, event) => {
    const newOrder = [...horseOrder];
    newOrder[index] = event.target.value;
    setHorseOrder(newOrder);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const subTotal = selectedAmount * selectedHorses.length;

    const betDetails = {
      bet_amount: selectedAmount,
      subTotal: subTotal,
      betType: selectedBet,
      horses: selectedHorses,
      horseOrder: horseOrder,
    };

    setBets([...bets, betDetails]);

    // Reset form fields
    setSelectedAmount(2);
    setSelectedBet("Win");
    setSelectedHorses([]);
    setHorseOrder([]);
  };

  const handleRemove = (indexToRemove) => {
    setBets((prevBets) => prevBets.filter((bet, index) => index !== indexToRemove));
  };

  const handleFinalize = () => {
    console.log("Finalizing bets with the following data:", bets);

    bets.forEach((bet, index) => {
      const betData = {
        bet_amount: bet.bet_amount,
        bet_total: bet.subTotal,
        bet_type: bet.betType,
        current_race_id: currentRaceId,
        track_name: trackName,
        bet_num_horses: bet.horses.length,
        user_id: localStorage.getItem("userId"),
        horse_order: bet.horseOrder,
      };

      bet.horses.forEach((horse, horseIndex) => {
        betData[`horse_id${horseIndex + 1}`] = horse.value;
      });

      axios
        .post(`${apiUrl}/user_bets`, betData)
        .then((response) => {
          console.log(`Bet ${index + 1} added successfully`, response.data);
        })
        .catch((error) => {
          console.error(`Error adding bet ${index + 1}:`, error);
        });
    });

    navigate(`/race`);
  };

  const betTotal = bets.reduce((total, bet) => total + parseInt(bet.subTotal), 0);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleNavigateToAdvanceBetTrack = () => {
    navigate("/advanced-bet-tracks", {
      state: {
        trackName,
        currentRaceId,
        selectedBet,
      },
    });
  };

  const isAdvancedBet = (betType) => {
    const advancedBetTypes = [
      "Daily Double",
      "Pick 3",
      "Pick 4",
      "Pick 5",
      "Pick 6",
      "Pick 7",
      "Pick 8",
      "Pick 9",
      "Pick 10",
    ];
    return advancedBetTypes.includes(betType);
  };

  const isFormValid = () => {
    return (
      selectedBet &&
      selectedHorses.length === maxHorsesSelectable &&
      selectedAmount > 0 &&
      (!requiresOrder(selectedBet) || horseOrder.every((order) => order !== ""))
    );
  };

  const requiresOrder = (betType) => {
    return ["Trifecta", "Exacta", "Superfecta"].includes(betType);
  };

  if (!selectedHorseNames) {
    return <div>Loading...</div>;
  }

  const filteredHorses = selectedHorseNames.filter((horseName) =>
    horseName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ marginTop: "4vh" }}>
      <button onClick={handleGoBack} style={{ marginLeft: "3vw", marginTop: "4vh" }}>
        Go Back
      </button>
      <div style={{ display: "flex" }}>
        <div style={{ width: "34%", marginLeft: "3vw", marginTop: "1vw" }}>
          <Clock showCountdown={true} />
          <figure style={{ maxWidth: "75%", marginTop: "-1vh" }}>
            <img src={horseImage} alt="horserace" style={{ marginLeft: "2.5vw", maxWidth: "75%", marginTop: "1vh" }} />
          </figure>
          <div style={{ paddingRight: "1vw" }}>
            <p>
              To place a bet, select a bet type and the horses you want to bet on, then enter your bet amount and select
              the horse(s) and click "Place Bet". The odds on Bets can vary depending on the number of possible outcomes
              and the amount of money wagered on each outcome.
            </p>
            <p>
              The odds are calculated by multiplying the odds of each individual selection. For example, if the odds of
              the first selection are 2:1, the odds of the second selection are 3:1, and the odds of the third selection
              are 4:1, the overall odds of the Pick 3 bet would be 2 x 3 x 4 = 24:1.
            </p>
            <p>
              However, it's important to note that the odds and payouts can differ depending on the specific track and
              race, as well as the amount of money wagered by other bettors. Statistics for each horse, jockey, trainer,
              etc can be found in the menu system.
            </p>
          </div>
        </div>
        <div style={{ width: "auto", marginLeft: "12vw", position: "relative" }}>
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "row",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "20px",
              boxShadow: "3px 3px 6px 8px darkgrey",
            }}
          >
            <div style={{ marginRight: "1rem", width: "40%" }}>
              <div>
                <label htmlFor="bet-type">Select Bet Type:</label>
                <select id="bet-type" onChange={handleBetChange} value={selectedBet}>
                  <option value="">--Select Bet Type--</option>
                  {cachedBetTypes.map((betType) => (
                    <option key={betType.id} value={betType.bet_type}>
                      {betType.bet_type}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <input type="checkbox" checked={showDescription} onChange={handleCheckboxChange} />
                <label>Show Description</label>
                {showDescription && (
                  <div>
                    <p style={{ color: "darkblue", fontSize: "12pt" }}>{betTypeDescription}</p>
                  </div>
                )}
              </div>
              <div>
                <label htmlFor="amount">Bet Amount:</label>
                <input
                  id="amount"
                  type="number"
                  min="0"
                  step="1"
                  value={selectedAmount}
                  onChange={handleAmountChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    marginTop: "4px",
                    marginBottom: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    boxSizing: "border-box",
                  }}
                />
              </div>
            </div>
            <div style={{ width: "auto%" }}>
              <div>
                <label htmlFor="horses">Select Horses:</label>
                <select
                  id="horses"
                  multiple
                  onChange={handleHorseChange}
                  value={selectedHorses ? selectedHorses.map((horse) => horse.value) : []}
                  style={{
                    width: "auto",
                    maxHeight: "44vh",
                    height: "26vh",
                    padding: "8px",
                    marginTop: "4px",
                    marginBottom: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    boxSizing: "border-box",
                  }}
                >
                  {filteredHorses.map((horseName, index) => (
                    <option key={index} value={selectedHorseIds[index]}>
                      {horseName}
                    </option>
                  ))}
                </select>
              </div>
              {requiresOrder(selectedBet) && (
                <div>
                  <label>Order:</label>
                  {selectedHorses.map((horse, index) => (
                    <div key={index}>
                      <span>{horse.label}</span>
                      <input
                        type="number"
                        min="1"
                        max={maxHorsesSelectable}
                        value={horseOrder[index] || ""}
                        onChange={(e) => handleOrderChange(index, e)}
                        style={{ width: "50px", marginLeft: "10px" }}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div>
                <button
                  type="submit"
                  disabled={!isFormValid()}
                  style={{
                    backgroundColor: "#4CAF50",
                    color: "white",
                    padding: "14px 20px",
                    marginTop: "8px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    width: "auto",
                  }}
                >
                  Add Bet
                </button>
              </div>
            </div>
          </form>
          <div style={{ width: "auto%", marginLeft: "1vw", marginTop: "2vh", position: "relative" }}>
            {bets.length > 0 && (
              <div style={{ width: "auto" }}>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "4vw" }}>Bet Type</th>
                      <th>Amount</th>
                      <th style={{ width: "8vw" }}>Horses</th>
                      <th style={{ width: "4vw" }}>Sub-Total</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bets.map((bet, index) => (
                      <tr key={index}>
                        <td>{bet.betType}</td>
                        <td>${bet.bet_amount}</td>
                        <td>
                          {bet.horses.map((horse, horseIndex) => (
                            <span key={horseIndex}>
                              {horse.label} {bet.horseOrder[horseIndex] && `(Order: ${bet.horseOrder[horseIndex]})`}
                              {horseIndex < bet.horses.length - 1 && ", "}
                            </span>
                          ))}
                        </td>
                        <td>${bet.subTotal}</td>
                        <td>
                          <button type="button" onClick={() => handleRemove(index)}>
                            Remove Bet
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="3">Total Bet:</td>
                      <td>${formatCurrency(betTotal)}</td>
                      <td>
                        <button type="button" onClick={handleFinalize}>
                          Place Bet
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            )}
            {isAdvancedBet(selectedBet) && (
              <div>
                <button
                  onClick={handleNavigateToAdvanceBetTrack}
                  style={{
                    backgroundColor: "#007BFF",
                    color: "white",
                    padding: "14px 20px",
                    marginTop: "8px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    width: "auto",
                  }}
                >
                  Advanced Bet
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bets;
