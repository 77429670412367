import React from 'react';
const apiUrl = process.env.REACT_APP_API_BASE_URL;

function JockeyStats(props) {
    return (
        <div>
            Jockey Stats
        </div>
    );
}

export default JockeyStats;