import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import FormatKey from "../Modules/FormatKey"; // Import FormatKey component
import "../Styles/RaceResults.css"; // Import the CSS file


const apiUrl = process.env.REACT_APP_API_BASE_URL;


const RaceResults = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { race_result_id } = location.state || {}; // Make sure this is passed from the Race component
  const [raceResults, setRaceResults] = useState({});
  const [numColumns, setNumColumns] = useState(13); // Default to 13 columns
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchRaceResults = async () => {
      try {
        console.log(`Fetching race results for race result ID: ${race_result_id}`);
        const response = await axios.get(`${apiUrl}/race_results/${race_result_id}`);
        console.log("Race results fetched successfully:", response.data);
        setRaceResults(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching race results:", error);
        setError("Error fetching race results. Please try again later.");
        setLoading(false);
      }
    };

    if (race_result_id) {
      fetchRaceResults();
    } else {
      setError("No race result ID provided.");
      setLoading(false);
    }
  }, [race_result_id]);

  const handleNumColumnsChange = (event) => {
    setNumColumns(parseInt(event.target.value, 10));
  };

  const handleNavigation = (type, id) => {
    navigate(`/page-${type}/${id}`);
  };

  const renderCellContent = (key, value) => {
    if (Array.isArray(value)) {
      return value.map((item, index) => (
        <div key={index} style={{ whiteSpace: 'pre-wrap' }}>
          {typeof item === 'object' ? JSON.stringify(item) : item}
        </div>
      ));
    }

    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }

    if (key === "horse_id" || key === "owner_id" || key === "jockey_id") {
      const type = key.split('_')[0]; // Extract 'horse', 'owner', or 'jockey'
      return (
        <span
          onClick={() => handleNavigation(type, value)}
          style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
        >
          {value}
        </span>
      );
    }
    return value;
  };

  const allKeys = raceResults ? Object.keys(raceResults) : [];

  return (
    <div className="race-results-container">
      <h2>Race Results</h2>
      <h3>Race Result ID #{race_result_id}</h3>

      {error ? (
        <p>{error}</p>
      ) : loading ? (
        <p>Loading race results...</p>
      ) : (
        <>
          <label>
            Number of columns to display:
            <select value={numColumns} onChange={handleNumColumnsChange} style={{ marginLeft: '1%', width: "5%" }}>
              {allKeys.slice(1).map((_, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </label>

          <table className="styled-table">
            <thead>
              <tr>
                {allKeys.slice(1, numColumns + 1).map((key) => (
                  <th key={key}>
                    <FormatKey keyText={key} /> {/* Use FormatKey to format column headers */}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {allKeys.slice(1, numColumns + 1).map((key) => (
                  <td key={key}>{renderCellContent(key, raceResults[key])}</td>
                ))}
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default RaceResults;
