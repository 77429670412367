
// import React, { useEffect, useState, useContext } from 'react'; // Import useContext
// import axios from 'axios';
// import { FreeContext } from "../Pages/FreeContext"; // Import the FreeContext

// const FreeRandomData = () => {
//   const { setRandomData } = useContext(FreeContext); // Use useContext

//   useEffect(() => {
//     // Function to fetch random records from the API
//     const fetchRandomRecords = async (endpoint) => {
//       try {
//         const response = await axios.get(`${apiUrl}/random-records/${endpoint}/50`);
//         const randomRecords = response.data;
//         return randomRecords;
//       } catch (error) {
//         console.error(`Error fetching ${endpoint} data:`, error);
//         return [];
//       }
//     };

//     // Define the endpoints to fetch random records
//     const endpoints = ['horses', 'jockeys', 'trainers', 'owners'];

//     // Fetch random records for each endpoint and update the context state
//     const fetchData = async () => {
//       const randomData = {};
//       for (const endpoint of endpoints) {
//         console.log(`Fetching random records for: ${endpoint}`);
//         const records = await fetchRandomRecords(endpoint);
//         console.log(`Fetched random records for: ${endpoint}`, records);
//         randomData[endpoint] = records;
//       }
//       console.log('Fetched data:', randomData);

//       setRandomData(randomData); // Update the context state
//     };

//     fetchData();
//   }, [setRandomData]);
// //Return null so the data does not render in othe components
//   return null;
// };

// export default FreeRandomData;

//Same as above except with comments
import React, { useEffect, useContext } from 'react';
import axios from 'axios';
import { FreeContext } from "../Pages/FreeContext";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const FreeRandomData = ({ count }) => { // Accept 'count' as a prop from the calling component
  const { setRandomData } = useContext(FreeContext);

  useEffect(() => {
    // Function to fetch random records from the API
    const fetchRandomRecords = async (endpoint) => {
      try {
        const response = await axios.get(`${apiUrl}/random-records/${endpoint}/${count}`);
        const randomRecords = response.data;
        return randomRecords;
      } catch (error) {
        console.error(`Error fetching ${endpoint} data:`, error);
        return [];
      }
    };

    // Define the endpoints to fetch random records
    const endpoints = ['horses', 'jockeys', 'trainers', 'owners', 'tracks'];

    // Fetch random records for each endpoint and update the context state
    const fetchData = async () => {
      const randomData = {};
      for (const endpoint of endpoints) {
        // console.log(`Fetching ${count} random records for: ${endpoint}`);
        const records = await fetchRandomRecords(endpoint);
        // console.log(`Fetched ${count} random records for: ${endpoint}`, records);
        randomData[endpoint] = records;
      }
      // console.log('Fetched data:', randomData);

      setRandomData(randomData);
    };

    fetchData();
  }, [setRandomData, count]); 

  return null;
};

export default FreeRandomData;
