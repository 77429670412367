import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { RaceContext } from '../Pages/RaceContext';  // Import the context


const apiUrl = process.env.REACT_APP_API_BASE_URL;


function MorningLineOdds() {
  const { selectedHorseIds } = useContext(RaceContext);  // Use the context to get the selected horse IDs

  const [performanceData, setPerformanceData] = useState([]);
  const [topThree, setTopThree] = useState([]);

  useEffect(() => {
    const fetchPerformanceData = async () => {
      try {
        if (selectedHorseIds.length === 0) {
          throw new Error('No horse IDs provided');
        }

        const promises = selectedHorseIds.map(horseId =>
          axios.get(`${apiUrl}/past_performance/horse_id/${horseId}`)
            .then(response => response.data)
            .catch(err => {
              console.error('Failed to fetch for horseId:', horseId, err);
              return null;
            })
        );

        const results = await Promise.all(promises);
        const validResults = results.filter(result => result !== null);
        const scoredData = calculateScores(validResults);
        setPerformanceData(scoredData);

        if (scoredData.length > 0) {
          const sortedData = scoredData.sort((a, b) => b.score - a.score);
          setTopThree(sortedData.slice(0, 3));
        }
      } catch (error) {
        console.error('Error fetching performance data:', error);
      }
    };

    fetchPerformanceData();
  }, [selectedHorseIds]);

  const calculateScores = (data) => {
    return data.map(horse => {
      const score = calculateScore(horse);
      return { ...horse, score };
    });
  };

  const calculateScore = (horse) => {
    const weights = {
      win_rate: 0.2,
      average_speed: 0.3,
      earnings: 0.1,
      last_race_time: 0.3,
      jockey_win_rate: 0.1
    };

    console.log(`Values for ${horse.horse_name}:`, {
      win_rate: horse.win_pct_horse,
      average_speed: horse.avg_speed_figure_horse,
      earnings: horse.earnings,
      last_race_time: horse.last_race_time,
      jockey_win_rate: horse.win_percentage_jockey
    });

    return (
      (parseFloat(horse.win_pct_horse) || 0) * weights.win_rate +
      (parseFloat(horse.avg_speed_figure_horse) || 0) * weights.average_speed +
      (Math.log(parseFloat(horse.earnings) || 1)) * weights.earnings +
      (1 / (parseFloat(horse.last_race_time) || 1)) * weights.last_race_time +
      (parseFloat(horse.win_percentage_jockey) || 0) * weights.jockey_win_rate
    );
  };

  const calculateOdds = (data) => {
    const totalScore = data.reduce((acc, horse) => acc + horse.score, 0);
    return data.map(horse => {
      const probability = horse.score / totalScore;
      const odds = (1 / probability) - 1;
      return { ...horse, odds: odds.toFixed(2) };
    });
  };

  const oddsData = calculateOdds(performanceData);

  return (
    <div>
      <h1>Morning Line</h1>
      <h2>Top Three Horses</h2>
      <ul>
        {topThree.map(horse => (
          <li key={horse.horse_id}>{horse.horse_name} (Score: {horse.score.toFixed(2)})</li>
        ))}
      </ul>
      <table>
        <thead>
          <tr>
            <th>Horse Name</th>
            <th>Horse ID</th>
            <th>Jockey ID</th>
            <th>Score</th>
            <th>Odds</th>
          </tr>
        </thead>
        <tbody>
          {oddsData.map(horse => (
            <tr key={horse.horse_id}>
              <td>{horse.horse_name}</td>
              <td>{horse.horse_id}</td>
              <td>{horse.jockey_id}</td>
              <td>{horse.score !== undefined ? horse.score.toFixed(2) : 'N/A'}</td>
              <td>{horse.odds !== undefined ? horse.odds : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MorningLineOdds;
