import React from 'react';
const apiUrl = process.env.REACT_APP_API_BASE_URL;

function TrainerHistory(props) {
    return (
        <div>
            Trainer History
        </div>
    );
}

export default TrainerHistory;