//This is a utility to manually. To manually run.The scanning of the public slash static folder.
//To find any graphic images that do not exist in the database and list them so that they can be utilized.



import React, { useState } from 'react';
import axios from 'axios';


const apiUrl = process.env.REACT_APP_API_BASE_URL;


const RunScanInsert = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleRunScanInsert = async () => {
    try {
      setLoading(true);
      const response = await axios.get(' ${apiUrl}/run_scan_insert');
      setMessage(response.data);
    } catch (error) {
      console.error(error);
      setMessage('Error occurred while running scan and insert process.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button onClick={handleRunScanInsert} disabled={loading}>
        {loading ? 'Running...' : 'Run Scan and Insert'}
      </button>
      <p>{message}</p>
    </div>
  );
};

export default RunScanInsert;
