import React from 'react';
import Iframe from 'react-iframe';




const apiUrl = process.env.REACT_APP_API_BASE_URL;


function Instructions() {
  const pdfFile = process.env.PUBLIC_URL + '/documents/HowToPlayHotponies.pdf';

  return (
    <div  style={{margin: '-.51%'}} >
      <h1 style={{ margin: '-1%'}}>Instructions</h1> 
      <h3 style={{textAlign: 'center'}}>(Hores Racing)</h3>
      <div style={{alignContent: 'center', width: '80vw', height: '94vh'}}>
        <Iframe
          src={pdfFile}
          id="pdfViewer"
          className="pdfViewer"
          width="96%"
          height="96%"
        />
      </div>
    </div>
  );
}

export default Instructions;
