import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Styles/Earnings.css';


const apiUrl = process.env.REACT_APP_API_BASE_URL;


function Earnings() {
  const [horses, setHorses] = useState([]);
  const [selectedHorse, setSelectedHorse] = useState(null);
  const [earnings, setEarnings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAllHorses, setShowAllHorses] = useState(false);
  const [jockeys, setJockeys] = useState([]);
  const [selectedJockey, setSelectedJockey] = useState(null);
  const [jockeyEarnings, setJockeyEarnings] = useState(null);
  const [trainers, setTrainers] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const [trainerEarnings, setTrainerEarnings] = useState(null);
  const [owners, setOwners] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [ownerEarnings, setOwnerEarnings] = useState(null);

  useEffect(() => {
    axios.get(' ${apiUrl}/horses')
      .then(response => {
        setHorses(response.data);
        setSelectedHorse(response.data[0]);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (selectedHorse) {
      axios.get(`${apiUrl}/horses/${selectedHorse.id}`)
        .then(response => {
          setEarnings(response.data.earnings);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [selectedHorse]);

  useEffect(() => {
    axios.get(' ${apiUrl}/jockeys')
      .then(response => {
        setJockeys(response.data);
        setSelectedJockey(response.data[0]);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (selectedJockey) {
      axios.get(`${apiUrl}/jockeys/${selectedJockey.id}`)
        .then(response => {
          setJockeyEarnings(response.data.earnings);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [selectedJockey]);

  useEffect(() => {
    axios.get(' ${apiUrl}/trainers')
      .then(response => {
        setTrainers(response.data);
        setSelectedTrainer(response.data[0]);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (selectedTrainer) {
      axios.get(`${apiUrl}/trainers/${selectedTrainer.id}`)
        .then(response => {
          setTrainerEarnings(response.data.earnings);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [selectedTrainer]);

  useEffect(() => {
    axios.get(`${apiUrl}/owners`)
      .then(response => {
        setOwners(response.data);
        setSelectedOwner(response.data[0]);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (selectedOwner) {
      axios.get(`${apiUrl}/owners/${selectedOwner.id}`)
        .then(response => {
          setOwnerEarnings(response.data.earnings);
        })
        .catch(error => {console.log(error);
        });
        }
        }, [selectedOwner]);
        
        const handleHorseChange = (event) => {
        const selectedHorseId = event.target.value;
        const selectedHorse = horses.find(horse => horse.id === parseInt(selectedHorseId));
        setSelectedHorse(selectedHorse);
        };
        
        const handleJockeyChange = (event) => {
        const selectedJockeyId = event.target.value;
        const selectedJockey = jockeys.find(jockey => jockey.id === parseInt(selectedJockeyId));
        setSelectedJockey(selectedJockey);
        };
        
        const handleTrainerChange = (event) => {
        const selectedTrainerId = event.target.value;
        const selectedTrainer = trainers.find(trainer => trainer.id === parseInt(selectedTrainerId));
        setSelectedTrainer(selectedTrainer);
        };
        
        const handleOwnerChange = (event) => {
        const selectedOwnerId = event.target.value;
        const selectedOwner = owners.find(owner => owner.id === parseInt(selectedOwnerId));
        setSelectedOwner(selectedOwner);
        };
        
        const handleShowAllHorses = () => {
        setShowAllHorses(!showAllHorses);
        };
        
        return (
        <div className="earnings-container">
        {loading && <div>Loading...</div>}
        {!loading && (
        <div>
        <h2>Earnings</h2>
        <div className="earnings-selector">
        <div className="selector">
        <label htmlFor="horse-select">Horse:</label>
        <select id="horse-select" onChange={handleHorseChange} value={selectedHorse ? selectedHorse.id : ''}>
        {horses.map(horse => (
        <option key={horse.id} value={horse.id}>{horse.name}</option>
        ))}
        </select>
        </div>
        <div className="selector">
        <label htmlFor="jockey-select">Jockey:</label>
        <select id="jockey-select" onChange={handleJockeyChange} value={selectedJockey ? selectedJockey.id : ''}>
        {jockeys.map(jockey => (
        <option key={jockey.id} value={jockey.id}>{jockey.name}</option>
        ))}
        </select>
        </div>
        <div className="selector">
        <label htmlFor="trainer-select">Trainer:</label>
        <select id="trainer-select" onChange={handleTrainerChange} value={selectedTrainer ? selectedTrainer.id : ''}>
        {trainers.map(trainer => (
        <option key={trainer.id} value={trainer.id}>{trainer.name}</option>
        ))}
        </select>
        </div>
        <div className="selector">
        <label htmlFor="owner-select">Owner:</label>
        <select id="owner-select" onChange={handleOwnerChange} value={selectedOwner ? selectedOwner.id : ''}>
        {owners.map(owner => (
        <option key={owner.id} value={owner.id}>{owner.name}</option>
        ))}
        </select>
        </div>
        <div className="show-all-horses">
        <button onClick={handleShowAllHorses}>{showAllHorses ? 'Hide All Horses' : 'Show All Horses'}</button>
        </div>
        </div>
        <div className="earnings-data">
        {selectedHorse && (
        <div className="horse-earnings">
        <h3>{selectedHorse.name}</h3>
        <div>Earnings: {earnings}</div>
        </div>
        )}
        {selectedJockey && (
        <div className="jockey-earnings">
        <h3>{selectedJockey.name}</h3>

<div>Earnings: {earnings}</div>
</div>
)}
{selectedTrainer && (
<div className="trainer-earnings">
<h3>{selectedTrainer.name}</h3>
<div>Earnings: {earnings}</div>
</div>
)}
{selectedOwner && (
<div className="owner-earnings">
<h3>{selectedOwner.name}</h3>
<div>Earnings: {earnings}</div>
</div>
)}
{showAllHorses && (
<div className="all-horses-earnings">
<h3>All Horses</h3>
<ul>
{horses.map(horse => (
<li key={horse.id}>{horse.name}: {horse.earnings}</li>
))}
</ul>
</div>
)}
</div>
</div>
)}
</div>
);
}
export default Earnings;
         





/////////////////////////////////////////////////
//   NEED DATABASE CONNECTION CORRECTED  TO LOCALHOST:3005

// import React, { useState, useEffect } from 'react';
// import '../Styles/Earnings.css';

// function Earnings() {
//   const [horses, setHorses] = useState([]);
//   const [selectedHorse, setSelectedHorse] = useState(null);
//   const [earnings, setEarnings] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [showAllHorses, setShowAllHorses] = useState(false);
//   const [jockeys, setJockeys] = useState([]);
//   const [selectedJockey, setSelectedJockey] = useState(null);
//   const [jockeyEarnings, setJockeyEarnings] = useState(null);
//   const [trainers, setTrainers] = useState([]);
//   const [selectedTrainer, setSelectedTrainer] = useState(null);
//   const [trainerEarnings, setTrainerEarnings] = useState(null);
//   const [owners, setOwners] = useState([]);
//   const [selectedOwner, setSelectedOwner] = useState(null);
//   const [ownerEarnings, setOwnerEarnings] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       const response = await fetch('https://example.com/api/horses');
//       const data = await response.json();
//       setHorses(data);
//       setSelectedHorse(data[0]);
//       setLoading(false);
//     };
//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (selectedHorse) {
//       const fetchData = async () => {
//         const response = await fetch(`https://example.com/api/horses/${selectedHorse.id}`);
//         const data = await response.json();
//         setEarnings(data.earnings);
//       };
//       fetchData();
//     }
//   }, [selectedHorse]);

//   useEffect(() => {
//     const fetchData = async () => {
//       const response = await fetch('https://example.com/api/jockeys');
//       const data = await response.json();
//       setJockeys(data);
//       setSelectedJockey(data[0]);
//     };
//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (selectedJockey) {
//       const fetchData = async () => {
//         const response = await fetch(`https://example.com/api/jockeys/${selectedJockey.id}`);
//         const data = await response.json();
//         setJockeyEarnings(data.earnings);
//       };
//       fetchData();
//     }
//   }, [selectedJockey]);

//   useEffect(() => {
//     const fetchData = async () => {
//       const response = await fetch('https://example.com/api/trainers');
//       const data = await response.json();
//       setTrainers(data);
//       setSelectedTrainer(data[0]);
//     };
//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (selectedTrainer) {
//       const fetchData = async () => {
//         const response = await fetch(`https://example.com/api/trainers/${selectedTrainer.id}`);
//         const data = await response.json();
//         setTrainerEarnings(data.earnings);
//       };
//       fetchData();
//     }
//   }, [selectedTrainer]);

//   useEffect(() => {
//     const fetchData = async () => {
//       const response = await fetch(`https://example.com/api/owners`);
//       const data = await response.json();
//       setOwners(data);
//       setSelectedOwner(data[0]);
//     };
//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (selectedOwner) {
//       const fetchData = async () => {
//         const response = await fetch(`https://example.com/api/owners/${selectedOwner.id}`);
// const data = await response.json();
// setOwnerEarnings(data.earnings);
// };
// fetchData();
//     }
// }, [selectedOwner]);

// const handleHorseSelect = (horse) => {
// setSelectedHorse(horse);
// };

// const handleJockeySelect = (jockey) => {
// setSelectedJockey(jockey);
// };

// const handleTrainerSelect = (trainer) => {
// setSelectedTrainer(trainer);
// };

// const handleOwnerSelect = (owner) => {
// setSelectedOwner(owner);
// };

// const handleShowAllHorses = () => {
// setShowAllHorses(!showAllHorses);
// };

// const filteredHorses = showAllHorses ? horses : horses.slice(0, 5);

// return (
//   <div className="earnings">
//     {loading && <div className="loading">Loading...</div>}
//     {!loading && (
//       <>
//         <h2>Earnings</h2>
//         <div className="earnings-filters">
//           <div className="filter">
//             <label htmlFor="horse-select">Horse:</label>
//             <select
//               id="horse-select"
//               value={selectedHorse.id}
//               onChange={(e) =>
//                 handleHorseSelect(
//                   horses.find((horse) => horse.id === e.target.value)
//                 )
//               }
//             >
//               {horses.map((horse) => (
//                 <option key={horse.id} value={horse.id}>
//                   {horse.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div className="filter">
//             <label htmlFor="jockey-select">Jockey:</label>
//             <select
//               id="jockey-select"
//               value={selectedJockey.id}
//               onChange={(e) =>
//                 handleJockeySelect(
//                   jockeys.find((jockey) => jockey.id === e.target.value)
//                 )
//               }
//             >
//               {jockeys.map((jockey) => (
//                 <option key={jockey.id} value={jockey.id}>
//                   {jockey.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div className="filter">
//             <label htmlFor="trainer-select">Trainer:</label>
//             <select
//               id="trainer-select"
//               value={selectedTrainer.id}
//               onChange={(e) =>
//                 handleTrainerSelect(
//                   trainers.find((trainer) => trainer.id === e.target.value)
//                 )
//               }
//             >
//               {trainers.map((trainer) => (
//                 <option key={trainer.id} value={trainer.id}>
//                   {trainer.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div className="filter">
//             <label htmlFor="owner-select">Owner:</label>
//             <select
//               id="owner-select"
//               value={selectedOwner.id}
//               onChange={(e) =>
//                 handleOwnerSelect(
//                   owners.find((owner) => owner.id === e.target.value)
//                 )
//               }
//             >
//               {owners.map((owner) => (
//                 <option key={owner.id} value={owner.id}>
//                   {owner.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <button className="show-all-horses" onClick={handleShowAllHorses}>
//             {showAllHorses ? "Show less horses" : "Show all horses"}
//           </button>
//         </div>
//         <div className="earnings-summary">
//           <div className="summary-box">
//             <div className="summary-box-label">Horse Earnings:</div>
//             <div className="summary-box-value">
//               {earnings ? `$${earnings}` : "-"}
//             </div>
//           </div>
//           <div className="summary-box">
//             <div className="summary-box-label">Jockey Earnings:</div>
//             <div className="summary-box-value">
//               {jockeyEarnings ? `$${jockeyEarnings}` : "-"}
//             </div>
//           </div>
//           <div className="summary-box">
//             <div className="summary-box-label">Trainer Earnings:</div>
//             <div className="summary-box-value">
//               {trainerEarnings ? `$${trainerEarnings}`: "-"} 
//               </div> 
//               </div> 
//               <div className="summary-box"> 
//               <div className="summary-box-label">Owner Earnings:</div> 
//               <div className="summary-box-value"> 
//               {ownerEarnings ? `$${ownerEarnings}` : "-"}
//               </div>
//               </div>
// </div>

// <div className="earnings-table">
// <table>
// <thead>
// <tr>
// <th>Horse</th>
// <th>Jockey</th>
// <th>Trainer</th>
// <th>Owner</th>
// <th>Earnings</th>
// </tr>
// </thead>
// <tbody>
// {earnings.map((earning) => (
// <tr key={earning.id}>
// <td>{earning.horseName}</td>
// <td>{earning.jockeyName}</td>
// <td>{earning.trainerName}</td>
// <td>{earning.ownerName}</td>
// <td>{`{earning.amount}`}</td>
// </tr>
// ))}
// </tbody>
// </table>
// </div>
// </>
// )}

//   </div>
// );}

// export default Earnings;











//////////////////////////////////////////////////
 // WORKING VERY NICELY


// import React, { useState, useEffect } from 'react';
// import '../Styles/Earnings.css';

// function Earnings() {
// const [horses, setHorses] = useState([]);
// const [selectedHorse, setSelectedHorse] = useState(null);
// const [earnings, setEarnings] = useState(null);
// const [loading, setLoading] = useState(true);
// const [showAllHorses, setShowAllHorses] = useState(false);
// const [jockeys, setJockeys] = useState([]);
// const [selectedJockey, setSelectedJockey] = useState(null);
// const [jockeyEarnings, setJockeyEarnings] = useState(null);
// const [trainers, setTrainers] = useState([]);
// const [selectedTrainer, setSelectedTrainer] = useState(null);
// const [trainerEarnings, setTrainerEarnings] = useState(null);
// const [owners, setOwners] = useState([]);
// const [selectedOwner, setSelectedOwner] = useState(null);
// const [ownerEarnings, setOwnerEarnings] = useState(null);

// useEffect(() => {
// const fetchData = async () => {
// const db = await openDatabase();
// const horseData = await db.all(`SELECT * FROM horses`);
// setHorses(horseData);
// setSelectedHorse(horseData[0]);
// setLoading(false);
// }
// fetchData();
// }, []);

// useEffect(() => {
// if (selectedHorse) {
// setEarnings({
// totalEarnings: selectedHorse.total_earnings,
// earningsPerStart: selectedHorse.earnings_per_start,
// earningsPerVenue: selectedHorse.earnings_per_venue,
// earningsClassComparison: selectedHorse.earnings_class_comparison,
// earningsAllHorsesComparison: selectedHorse.earnings_all_horses_comparison,
// });
// }
// }, [selectedHorse]);

// useEffect(() => {
// const fetchData = async () => {
// const db = await openDatabase();
// const jockeyData = await db.all(`SELECT * FROM jockeys`);
// setJockeys(jockeyData);
// setSelectedJockey(jockeyData[0]);
// }
// fetchData();
// }, []);

// useEffect(() => {
// if (selectedJockey) {
// setJockeyEarnings({
// totalEarnings: selectedJockey.total_earnings,
// earningsPerHorse: selectedJockey.earnings_per_horse,
// earningsPerRace: selectedJockey.earnings_per_race,
// earningsAllJockeysComparison: selectedJockey.earnings_all_jockeys_comparison,
// });
// }
// }, [selectedJockey]);

// useEffect(() => {
// const fetchData = async () => {
// const db = await openDatabase();
// const trainerData = await db.all(`SELECT * FROM trainers`);
// setTrainers(trainerData);
// setSelectedTrainer(trainerData[0]);
// }
// fetchData();
// }, []);

// useEffect(() => {
// if (selectedTrainer) {
// setTrainerEarnings({
// totalEarnings: selectedTrainer.total_earnings,
// earningsPerHorse: selectedTrainer.earnings_per_horse,
// earningsPerStart: selectedTrainer.earnings_per_start,
// earningsAllTrainersComparison: selectedTrainer.earnings_all_trainers_comparison,
// });
// }
// }, [selectedTrainer]);

// useEffect(() => {
//     const fetchData = async () => {
//       const db = await openDatabase();
//       const ownerData = await db.all("SELECT * FROM owners");
//       setOwners(ownerData);
//       setSelectedOwner(ownerData[0]);
//     }
//     fetchData();
//   }, []);

// useEffect(() => {
// if (selectedOwner) {
// setOwnerEarnings({
// totalEarnings: selectedOwner.total_earnings,
// earningsPerHorse: selectedOwner.earnings_per_horse,
// earningsPerRace: selectedOwner.earnings_per_race,
// earningsAllOwnersComparison: selectedOwner.earnings_all_owners_comparison,
// });
// }
// }, [selectedOwner]);

// return (
// <div className="earnings-container">
// <div className="earnings-selection-container">
// <h2>Select a horse:</h2>
// <select className="earnings-select" value={selectedHorse?.id} onChange={(e) => setSelectedHorse(horses.find(horse => horse.id === Number(e.target.value)))}>
// {horses.map(horse => (
// <option key={horse.id} value={horse.id}>{horse.name}</option>
// ))}
// </select>
// {earnings && (
// <div className="earnings-details-container">
// <h3>Earnings:</h3>
// <p>Total: {earnings.totalEarnings}</p>
// <p>Earnings per start: {earnings.earningsPerStart}</p>
// <p>Earnings per venue: {earnings.earningsPerVenue}</p>
// <p>Earnings vs. class average: {earnings.earningsClassComparison}%</p>
// <p>Earnings vs. all horses: {earnings.earningsAllHorsesComparison}%</p>
// </div>
// )}
// </div>
// <div className="earnings-selection-container">
// <h2>Select a jockey:</h2>
// <select className="earnings-select" value={selectedJockey?.id} onChange={(e) => setSelectedJockey(jockeys.find(jockey => jockey.id === Number(e.target.value)))}>
// {jockeys.map(jockey => (
// <option key={jockey.id} value={jockey.id}>{jockey.name}</option>
// ))}
// </select>
// {jockeyEarnings && (
// <div className="earnings-details-container">
// <h3>Earnings:</h3>
// <p>Total: {jockeyEarnings.totalEarnings}</p>
// <p>Earnings per horse: {jockeyEarnings.earningsPerHorse}</p>
// <p>Earnings per race: {jockeyEarnings.earningsPerRace}</p>
// <p>Earnings vs. all jockeys: {jockeyEarnings.earningsAllJockeysComparison}%</p>
// </div>
// )}
// </div>
// <div className="earnings-selection-container">
// <h2>Select a trainer:</h2>
// <select className="earnings-select" value={selectedTrainer?.id} onChange={(e) => setSelectedTrainer(trainers.find(trainer => trainer.id === Number(e.target.value)))}>
// {trainers.map(trainer => (
// <option key={trainer.id} value={trainer.id}>{trainer.name}</option>


// ))}
// </select>
// {trainerEarnings && (

// <div className="earnings-details-container">
// <h3>Earnings:</h3>
// <p>Total: {trainerEarnings.totalEarnings}</p>
// <p>Earnings per horse: {trainerEarnings.earningsPerHorse}</p>
// <p>Earnings per start: {trainerEarnings.earningsPerStart}</p>
// <p>Earnings vs. all trainers: {trainerEarnings.earningsAllTrainersComparison}%</p>
// </div>
// )}
// </div>
// <div className="earnings-selection-container">
// <h2>Select an owner:</h2>
// <select className="earnings-select" value={selectedOwner?.id} onChange={(e) => setSelectedOwner(owners.find(owner => owner.id === Number(e.target.value)))}>
// {owners.map(owner => (
// <option key={owner.id} value={owner.id}>{owner.name}</option>
// ))}
// </select>
// {ownerEarnings && (
// <div className="earnings-details-container">
// <h3>Earnings:</h3>
// <p>Total: {ownerEarnings.totalEarnings}</p>
// <p>Earnings per horse: {ownerEarnings.earningsPerHorse}</p>
// <p>Earnings per race: {ownerEarnings.earningsPerRace}</p>
// <p>Earnings vs. all owners: {ownerEarnings.earningsAllOwnersComparison}%</p>
// </div>
// )}
// </div>
// </div>
// );
// }
// export default Earnings;





//////////////////////////////////////////////

// CODE WORKS FINE BUT USES bootstrap


// import React, { useState, useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './Earnings.css';

// function Earnings() {
// const [horses, setHorses] = useState([]);
// const [selectedHorse, setSelectedHorse] = useState(null);
// const [earnings, setEarnings] = useState(null);
// const [loading, setLoading] = useState(true);
// const [showAllHorses, setShowAllHorses] = useState(false);
// const [jockeys, setJockeys] = useState([]);
// const [selectedJockey, setSelectedJockey] = useState(null);
// const [jockeyEarnings, setJockeyEarnings] = useState(null);
// const [trainers, setTrainers] = useState([]);
// const [selectedTrainer, setSelectedTrainer] = useState(null);
// const [trainerEarnings, setTrainerEarnings] = useState(null);
// const [owners, setOwners] = useState([]);
// const [selectedOwner, setSelectedOwner] = useState(null);
// const [ownerEarnings, setOwnerEarnings] = useState(null);

// useEffect(() => {
// // Sample horse data
// const sampleHorses = [
// {
// id: 1,
// name: 'Horse 1',
// sampleData: {
// totalEarnings: 50000,
// earningsPerStart: 2500,
// earningsPerVenue: 10000,
// earningsClassComparison: '+2%',
// earningsAllHorsesComparison: 'Top 50%'
// }
// },
// {
// id: 2,
// name: 'Horse 2',
// sampleData: {
// totalEarnings: 100000,
// earningsPerStart: 5000,
// earningsPerVenue: 15000,
// earningsClassComparison: '+5%',
// earningsAllHorsesComparison: 'Top 25%'
// }
// },
// {
// id: 3,
// name: 'Horse 3',
// sampleData: {
// totalEarnings: 75000,
// earningsPerStart: 3500,
// earningsPerVenue: 12000,
// earningsClassComparison: '+3%',
// earningsAllHorsesComparison: 'Top 40%'
// }
// },
// {
// id: 4,
// name: 'Horse 4',
// sampleData: {
// totalEarnings: 125000,
// earningsPerStart: 6000,
// earningsPerVenue: 18000,
// earningsClassComparison: '+7%',
// earningsAllHorsesComparison: 'Top 10%'
// }
// },
// ];
// setHorses(sampleHorses);
// setSelectedHorse(sampleHorses[0]);
// }, []);

// useEffect(() => {
// if (selectedHorse) {
// setEarnings(selectedHorse.sampleData);
// setLoading(false);
// }
// }, [selectedHorse]);

// useEffect(() => {
// // Sample jockey data
// const sampleJockeys = [
// {
// id: 1,
// name: 'Jockey 1',
// sampleData: {
// totalEarnings: 75000,
// earningsPerHorse: 10000,
// earningsPerRace: 2500,
// earningsAllJockeysComparison: 'Top 10%'
// }
// },
// {
// id: 2,
// name: 'Jockey 2',
// sampleData: {
// totalEarnings: 100000,
// earningsPerHorse: 12000,
// earningsPerRace: 3000,
// earningsAllJockeysComparison: 'Top 5%'
// }
// },
// {
// id: 3,
// name: 'Jockey 3',
// sampleData: {
// totalEarnings: 50000,
// earningsPerHorse: 8000,
// earningsPerRace: 2000,
// earningsAllJockeysComparison: 'Top 25%'
// }
// },
// {
// id: 4,
// name: 'Jockey 4',
// sampleData: {
// totalEarnings: 125000,
// earningsPerHorse: 15000,
// earningsPerRace: 3750,
// earningsAllJockeysComparison: 'Top 1%'
// }
// },
// ];
// setJockeys(sampleJockeys);
// setSelectedJockey(sampleJockeys[0]);
// }, []);

// useEffect(() => {
// if (selectedJockey) {
// setJockeyEarnings(selectedJockey.sampleData);
// setLoading(false);
// }
// }, [selectedJockey]);

// useEffect(() => {
// // Sample trainer data
// const sampleTrainers = [
// {
// id: 1,
// name: 'Trainer 1',
// sampleData: {
// totalEarnings: 100000,
// earningsPerHorse: 15000,
// earningsPerStart: 5000,
// earningsAllTrainersComparison: 'Top 10%'
// }
// },
// {
// id: 2,
// name: 'Trainer 2',
// sampleData: {
// totalEarnings: 125000,
// earningsPerHorse: 18000,
// earningsPerStart: 6000,
// earningsAllTrainersComparison: 'Top 5%'
// }
// },
// {
// id: 3,
// name: 'Trainer 3',
// sampleData: {
// totalEarnings: 75000,
// earningsPerHorse: 12000,
// earningsPerStart: 4000,
// earningsAllTrainersComparison: 'Top 25%'
// }
// },
// {
// id: 4,
// name: 'Trainer 4',
// sampleData: {
// totalEarnings: 150000,
// earningsPerHorse: 20000,
// earningsPerStart: 7500,
// earningsAllTrainersComparison: 'Top 1%'
// }
// },
// ];
// setTrainers(sampleTrainers);
// setSelectedTrainer(sampleTrainers[0]);
// }, []);

// useEffect(() => {
// if (selectedTrainer) {
// setTrainerEarnings(selectedTrainer.sampleData);
// setLoading(false);
// }
// }, [selectedTrainer]);

// useEffect(() => {
// // Sample owner data
// const sampleOwners = [
//     {
//     id: 1,
//     name: 'Owner 1',
//     sampleData: {
//     totalEarnings: 75000,
//     earningsPerHorse: 10000,
//     earningsPerStart: 2500,
//     earningsAllOwnersComparison: 'Top 10%'
//     }
//     },
//     {
//     id: 2,
//     name: 'Owner 2',
//     sampleData: {
//     totalEarnings: 100000,
//     earningsPerHorse: 12000,
//     earningsPerStart: 3000,
//     earningsAllOwnersComparison: 'Top 5%'
//     }
//     },
//     {
//     id: 3,
//     name: 'Owner 3',
//     sampleData: {
//     totalEarnings: 50000,
//     earningsPerHorse: 8000,
//     earningsPerStart: 2000,
//     earningsAllOwnersComparison: 'Top 25%'
//     }
//     },
//     {
//     id: 4,
//     name: 'Owner 4',
//     sampleData: {
//     totalEarnings: 125000,
//     earningsPerHorse: 15000,
//     earningsPerStart: 3750,
//     earningsAllOwnersComparison: 'Top 1%'
//     }
//     },
//     ];
//     setOwners(sampleOwners);
//     setSelectedOwner(sampleOwners[0]);
//     }, []);
    
//     useEffect(() => {
//     if (selectedOwner) {
//     setOwnerEarnings(selectedOwner.sampleData);
//     setLoading(false);
//     }
//     }, [selectedOwner]);
    
//     const handleHorseChange = (event) => {
//     const id = parseInt(event.target.value);
//     const horse = horses.find(horse => horse.id === id);
//     setSelectedHorse(horse);
//     };
    
//     const handleJockeyChange = (event) => {
//     const id = parseInt(event.target.value);
//     const jockey = jockeys.find(jockey => jockey.id === id);
//     setSelectedJockey(jockey);
//     };
    
//     const handleTrainerChange = (event) => {
//     const id = parseInt(event.target.value);
//     const trainer = trainers.find(trainer => trainer.id === id);
//     setSelectedTrainer(trainer);
//     };
    
//     const handleOwnerChange = (event) => {
//     const id = parseInt(event.target.value);
//     const owner = owners.find(owner => owner.id === id);
//     setSelectedOwner(owner);
//     };
    
//     return (
    
//     <div className="container">
//     <h1>Earnings</h1>
//     <div className="row">
//     <div className="col-12 col-md-6">
//     <div className="form-group">
//     <label htmlFor="horse-select">Select Horse</label>
//     <select className="form-control" id="horse-select" onChange={handleHorseChange}>
//     {horses.map(horse => (
//     <option key={horse.id} value={horse.id}>{horse.name}</option>
//     ))}
//     </select>
//     </div>
//     {loading && <p>Loading...</p>}
//     {!loading && selectedHorse && (
//     <div>
//     <h2>{selectedHorse.name} Earnings</h2>
//     <table className="table">
//     <thead>
//     <tr>
//     <th>Total Earnings</th>
//     <th>Earnings Per Start</th>
//     <th>Earnings Per Venue</th>
//     <th>Earnings Class Comparison</th>
//     <th>Earnings All Horses Comparison</th>
//     </tr>
//     </thead>
//     <tbody>
//     <tr>
//     <td>${earnings.totalEarnings}</td>
//     <td>${earnings.earningsPerStart}</td>
//     <td>${earnings.earningsPerVenue}</td>
//     <td>{earnings.earningsClassComparison}</td>
//     <td>{earnings.earningsAllHorsesComparison}</td>
//     </tr>
//     </tbody>
//     </table>
//     </div>
//     )}
//     </div>
//     <div className="col-12 col-md-6">
//     <div className="form-group">
//     <label htmlFor="jockey-select">Select Jockey</label>
//     <select className="form-control" id="jockey-select" onChange={handleJockeyChange}>
//     {jockeys.map(jockey => (
// <option key={jockey.id} value={jockey.id}>{jockey.name}</option>
// ))}
// </select>
// </div>
// {loading && <p>Loading...</p>}
// {!loading && selectedJockey && (
// <div>
// <h2>{selectedJockey.name} Earnings</h2>
// <table className="table">
// <thead>
// <tr>
// <th>Total Earnings</th>
// <th>Earnings Per Start</th>
// <th>Earnings Per Venue</th>
// <th>Earnings All Jockeys Comparison</th>
// </tr>
// </thead>
// <tbody>
// <tr>
// <td>${earnings.totalEarnings}</td>
// <td>${earnings.earningsPerStart}</td>
// <td>${earnings.earningsPerVenue}</td>
// <td>{earnings.earningsAllJockeysComparison}</td>
// </tr>
// </tbody>
// </table>
// </div>
// )}
// </div>
// </div>
// <div className="row">
// <div className="col-12 col-md-6">
// <div className="form-group">
// <label htmlFor="trainer-select">Select Trainer</label>
// <select className="form-control" id="trainer-select" onChange={handleTrainerChange}>
// {trainers.map(trainer => (
// <option key={trainer.id} value={trainer.id}>{trainer.name}</option>
// ))}
// </select>
// </div>
// {loading && <p>Loading...</p>}
// {!loading && selectedTrainer && (
// <div>
// <h2>{selectedTrainer.name} Earnings</h2>
// <table className="table">
// <thead>
// <tr>
// <th>Total Earnings</th>
// <th>Earnings Per Start</th>
// <th>Earnings Per Venue</th>
// <th>Earnings All Trainers Comparison</th>
// </tr>
// </thead>
// <tbody>
// <tr>
// <td>${earnings.totalEarnings}</td>
// <td>${earnings.earningsPerStart}</td>
// <td>${earnings.earningsPerVenue}</td>
// <td>{earnings.earningsAllTrainersComparison}</td>
// </tr>
// </tbody>
// </table>
// </div>
// )}
// </div>
// <div className="col-12 col-md-6">
// <div className="form-group">
// <label htmlFor="owner-select">Select Owner</label>
// <select className="form-control" id="owner-select" onChange={handleOwnerChange}>
// {owners.map(owner => (
// <option key={owner.id} value={owner.id}>{owner.name}</option>
// ))}
// </select>
// </div>
// {loading && <p>Loading...</p>}
// {!loading && selectedOwner && (
// <div>
// <h2>{selectedOwner.name} Earnings</h2>
// <table className="table">
// <thead>
// <tr>
// <th>Total Earnings</th>
// <th>Earnings Per Horse</th>
// <th>Earnings Per Start</th>
// <th>Earnings All Owners Comparison</th>
// </tr>
// </thead>
// <tbody>
// <tr>
// <td>${ownerEarnings.totalEarnings}</td>
// <td>${ownerEarnings.earningsPerHorse}</td>
// <td>${ownerEarnings.earningsPerStart}</td>
// <td>{ownerEarnings.earningsAllOwnersComparison}</td>

// </tr>
// </tbody>
// </table>
// </div>
// )}
// </div>
// </div>
// </div>
// );
// }
// export default Earnings;













