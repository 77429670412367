import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../Styles/CurrentRaceScreens.css';
import Clock from '../Modules/MathTime/Clock';
import CreateRaces from '../Modules/CreateRaces';


const apiUrl = process.env.REACT_APP_API_BASE_URL;


const CurrentRaceScreens = () => {
  const [horseImages, setHorseImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${apiUrl}/images_pics`);
        const allImages = response.data;
        setHorseImages(allImages.slice(0, 8)); // Limiting to 8 images
      } catch (error) {
        console.log(error);
      }
    };

    fetchImages();
  }, []);

  // Shuffle the horseImages array randomly
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const shuffledHorseImages = shuffleArray(horseImages);

  // Function to handle TV screen click
  const handleTVScreenClick = () => {
    navigate('/race'); // Navigate to the slash race screens component
  };

  return (
    <>
      <div style={{marginTop: '20vh', marginBottom:'-9%' }}>
        <Clock />
      </div>
     {/* <div><CreateRaces/></div>  */}
      <div className="current-races" style={{marginLeft:'20vw', marginTop: '-11vh', padding:'1%' }}>
        {shuffledHorseImages.map((image, index) => (
          <div
            key={index}
            className="television-screen"
            onClick={handleTVScreenClick} 
            style={{ cursor: 'pointer' }}
          >
            <img
              src={process.env.PUBLIC_URL + '/pics/' + image.image_name}
              alt={`Horse ${index + 1}`}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default CurrentRaceScreens;

