
import React, { useContext, useState, useEffect } from 'react';
import { FreeContext } from './FreeContext';
import FreeRandomData from '../Modules/FreeRandomData';
import { useNavigate } from 'react-router-dom';
import FormatKey from '../Modules/FormatKey';



const apiUrl = process.env.REACT_APP_API_BASE_URL;


const FreeLeaderboards = () => {
  const { randomData } = useContext(FreeContext);
  const navigate = useNavigate();
  const [sortedHorseData, setSortedHorseData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedEntityType, setSelectedEntityType] = useState(null);
  const [selectedEntityId, setSelectedEntityId] = useState(null);
  const [showAllFields, setShowAllFields] = useState(false);
  const [isOddsUpdating, setIsOddsUpdating] = useState(true); // Control if odds should keep updating

  // Function to generate random odds as a fraction (e.g., 5/1, 7/2, etc.)
  const generateRandomOdds = () => {
    const numerator = Math.floor(Math.random() * 10) + 1;
    const denominator = Math.floor(Math.random() * 10) + 1;
    return `${numerator}/${denominator}`;
  };

  // Function to update odds for a specific horse with a random delay
  const updateHorseOdds = (index) => {
    setSortedHorseData((prevData) => {
      const updatedOdds = [...prevData];
      updatedOdds[index].odds = generateRandomOdds();
      return updatedOdds;
    });

    // Set a new random timer for the next update (up to 30 seconds) until the maximum time window (5 minutes) is reached
    const nextUpdateDelay = isOddsUpdating ? Math.floor(Math.random() * 22000) + 8000 : 0;
    setTimeout(() => updateHorseOdds(index), nextUpdateDelay);
  };

  useEffect(() => {
    // Sort the horseData array by odds in ascending order when randomData changes
    const sortedData = randomData.horses
      .slice(0, 7)
      .map((horse, index) => ({
        horseName: horse.horse_name,
        horseId: horse.id,
        jockeyName: randomData.jockeys.length > index ? randomData.jockeys[index].jockey_name : '',
        jockeyId: randomData.jockeys.length > index ? randomData.jockeys[index].id : null,
        trainerName: randomData.trainers.length > index ? randomData.trainers[index].trainer_name : '',
        trainerId: randomData.trainers.length > index ? randomData.trainers[index].id : null,
        ownerName: randomData.owners.length > index ? randomData.owners[index].owner_name : '',
        ownerId: randomData.owners.length > index ? randomData.owners[index].id : null,
        trackName: randomData.tracks.length > index ? randomData.tracks[index].track_name : '',
        trackId: randomData.tracks.length > index ? randomData.tracks[index].id : null,
        odds: generateRandomOdds(),
      }))
      .sort((a, b) => parseOdds(a.odds) - parseOdds(b.odds));
    setSortedHorseData(sortedData);

    // Start the timer to update odds for each horse at random intervals (between 8 and 30 seconds)
    const timers = sortedData.map((_, index) => {
      const initialUpdateDelay = Math.floor(Math.random() * 22000) + 8000;
      setTimeout(() => updateHorseOdds(index), initialUpdateDelay);
    });

    // Set a timer to stop updating odds after 5 minutes
    const stopUpdatingTimer = setTimeout(() => setIsOddsUpdating(false), 300000); // 5 minutes (300,000 milliseconds)

    // Clean up the timers on component unmount
    return () => {
      timers.forEach((timer) => clearTimeout(timer));
      clearTimeout(stopUpdatingTimer);
    };
  }, [randomData]);

  const handleStartRace = () => {
    navigate('/free-race', { state: { horseData: sortedHorseData } });
  };

  const handleEntityClick = (entityId, entityType) => {
    setSelectedEntityId(entityId);
    setSelectedEntityType(entityType);

    let selectedEntity = null;
    if (entityType === 'horse') {
      selectedEntity = randomData.horses.find((horse) => horse.id === entityId);
    } else if (entityType === 'jockey') {
      selectedEntity = randomData.jockeys.find((jockey) => jockey.id === entityId);
    } else if (entityType === 'trainer') {
      selectedEntity = randomData.trainers.find((trainer) => trainer.id === entityId);
    } else if (entityType === 'owner') {
      selectedEntity = randomData.owners.find((track) => track.id === entityId);
    }else if (entityType === 'track') {
      selectedEntity = randomData.tracks.find((track) => track.id === entityId);
    }

    setSelectedData(selectedEntity);

    // Navigate to the corresponding entity page directly
    navigate(`/${entityType}/${entityId}`);
  };

  const parseOdds = (odds) => {
    const [numerator, denominator] = odds.split('/').map((val) => parseInt(val));
    return numerator / denominator;
  };

  return (
    <div style={{ marginTop: '4%', marginLeft: '2%', display: 'flex' }}>
      <div style={{ marginRight: '3%' }}>
        <div style={{ flex: 1 }}>
          <h2>Race Details:</h2>
          <button onClick={handleStartRace}>Start Race</button>
          {sortedHorseData.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Post</th>
                  <th>No.</th>
                  <th>
                    {/* Apply FormatKey component to format the column header */}
                    <FormatKey keyText="horse_name" />
                  </th>
                  <th>
                    {/* Apply FormatKey component to format the column header */}
                    <FormatKey keyText="jockey_name" />
                  </th>
                  <th>
                    {/* Apply FormatKey component to format the column header */}
                    <FormatKey keyText="trainer_name" />
                  </th>
                  <th>
                    {/* Apply FormatKey component to format the column header */}
                    <FormatKey keyText="owner_name" />
                  </th>
                  <th>Odds</th>
                </tr>
              </thead>
              <tbody>
                {sortedHorseData.map((horse, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{horse.horseId}</td>
                    <td
                      onClick={() => handleEntityClick(horse.horseId, 'horse')}
                      style={{ cursor: 'pointer' }}
                    >
                      {horse.horseName}
                    </td>
                    <td
                      onClick={() => handleEntityClick(horse.jockeyId, 'jockey')}
                      style={{ cursor: 'pointer' }}
                    >
                      {horse.jockeyName}
                    </td>
                    <td
                      onClick={() => handleEntityClick(horse.trainerId, 'trainer')}
                      style={{ cursor: 'pointer' }}
                    >
                      {horse.trainerName}
                    </td>
                    <td
                      onClick={() => handleEntityClick(horse.ownerId, 'owner')}
                      style={{ cursor: 'pointer' }}
                    >
                      {horse.ownerName}
                    </td>
                    <td>{horse.odds}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No horse names available.</div>
          )}
          {!showAllFields && sortedHorseData.length > 20 && (
            <button onClick={() => setShowAllFields(true)}>View More Fields</button>
          )}
        </div>
      </div>
      {selectedData && (
        <div style={{ flex: 1 }}>
          <h2>Selected Data:</h2>
          {selectedEntityType && selectedData && (
            <>
              <strong>
                {selectedEntityType.charAt(0).toUpperCase() + selectedEntityType.slice(1)} Name:
              </strong>{' '}
              {selectedData[selectedEntityType + '_name']}
              {Object.entries(selectedData)
                .slice(0, showAllFields ? Object.entries(selectedData).length : 20)
                .map(([key, value]) => (
                  <div key={key}>
                    <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
                  </div>
                ))}
              {showAllFields && Object.entries(selectedData).length > 20 && (
                <button onClick={() => setShowAllFields(false)}>View Less Fields</button>
              )}
            </>
          )}
        </div>
      )}
      <FreeRandomData count={50} />
    </div>
  );
};

export default FreeLeaderboards;
