import React, { useEffect, useState } from 'react';


const apiUrl = process.env.REACT_APP_API_BASE_URL;


const RSSFeed = () => {
  const [feedData, setFeedData] = useState([]);

  useEffect(() => {
    const fetchFeedData = async () => {
      try {
        const response = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https://www.offtrackbetting.com/rss-news-2.0.xml');
        const data = await response.json();
        setFeedData(data.items.slice(0, 4)); // Fetching only the first four items
      } catch (error) {
        console.log('Error fetching RSS feed:', error);
      }
    };

    fetchFeedData();
  }, []);

  return (
    <div>
      <h2>RSS Feed</h2>
      <div style={{ display: 'flex' }}>
        {feedData.map((item) => (
          <div key={item.guid} style={{ width: '400px', height: '300px', margin: '10px' }}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              <img src={item.thumbnail} alt={item.title} style={{ width: '100%', height: 'auto' }} />
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RSSFeed;



/////////////////////////CREATES A MODAL ///////////////////////////
// import React, { useEffect, useState } from 'react';

// const RSSFeed = () => {
//   const [feedData, setFeedData] = useState([]);
//   const [selectedItem, setSelectedItem] = useState(null);

//   useEffect(() => {
//     const fetchFeedData = async () => {
//       try {
//         const response = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https://www.offtrackbetting.com/rss-news-2.0.xml');
//         const data = await response.json();
//         setFeedData(data.items.slice(0, 4)); // Fetching only the first four items
//       } catch (error) {
//         console.log('Error fetching RSS feed:', error);
//       }
//     };

//     fetchFeedData();
//   }, []);

//   const openItem = (item) => {
//     setSelectedItem(item);
//   };

//   const closeItem = () => {
//     setSelectedItem(null);
//   };

//   return (
//     <div>
//       <h2>RSS Feed</h2>
//       <div style={{ display: 'flex' }}>
//         {feedData.map((item) => (
//           <div key={item.guid} style={{ width: '400px', height: '300px', margin: '10px' }}>
//             <div onClick={() => openItem(item)}>
//               <img src={item.thumbnail} alt={item.title} style={{ width: '100%', height: 'auto' }} />
//               <h3>{item.title}</h3>
//               <p>{item.description}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//       {selectedItem && (
//         <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
//           <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: '#fff', padding: '20px' }}>
//             <h2>{selectedItem.title}</h2>
//             <p>{selectedItem.description}</p>
//             <button onClick={closeItem}>Close</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default RSSFeed;
