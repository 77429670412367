
// This component's job is to check if you have the right key (authentication token) to access a protected feature. 
// If you do, it lets you in; if you don't, it redirects you to another page while explaining why you can't access the feature.

import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { getAuthToken } from './authUtils';
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ProtectedRoutes = () => {
  // Step 1: Check if the user is authenticated by getting the authentication token
  const authToken = getAuthToken(); 

  // Step 2: Determine if the user is authenticated based on the presence of the token
  const isAuthenticated = !!authToken; 

  // Step 3: Conditional rendering based on authentication status
  return (
    // Step 4: If authenticated, render the protected feature
    isAuthenticated ? (
      <Outlet />
    ) : (
      // Step 5: If not authenticated, redirect to the "/home" page
      <Navigate
        to="/home"
        // Step 6: Optionally pass the current route as state to the "/home" route
        state={{ from: window.location.pathname }}
        replace
      >
        {/* Step 7: Display a message informing the user to log in to access the feature */}
        <div style={{ marginTop: '5%' }}>
          <h3>Please log in to access this feature.</h3>
        </div>
      </Navigate>
    )
  );
};

export default ProtectedRoutes;


// Detailed description of the functionality

// **Step 1: Checking Authentication**
// - When you visit a specific page in the application, this component gets into action.
// - Its primary job is to check if you are authenticated, which means if you are allowed to access that page or feature.

// **Step 2: Getting Authentication Token**
// - To check if you are authenticated, the component first fetches your authentication token.
// - Think of this token as a special key that proves you have the right to access certain parts of the application.

// **Step 3: Verifying Authentication**
// - Once it has the token, the component checks if you have one.
// - If you do have a token, it means you are authenticated, and you have the right to access the protected feature.

// **Step 4: Displaying the Feature or Redirecting**
// - If you are authenticated (have a token), the component allows you to see the protected feature.
// - It's like opening the door and letting you in because you have the right key.

// **Step 5: Not Authenticated - Redirecting**
// - If you don't have a token (not authenticated), the component doesn't let you access the protected feature.
// - Instead, it redirects you to another page, "/home," which is like being redirected to a different place in the app.

// **Step 6: Optional: Passing Route Information**
// - As it redirects you, it can also tell the "/home" page where you were trying to go.
// - This is like giving a note to the "/home" page that says, "The user was trying to access this page."

// **Step 7: Displaying a Message**
// - While redirecting, it also displays a message on the screen.
// - This message politely tells you that you need to log in to access the feature.
// - It's like a friendly sign at a closed door that says, "Please show your ID to enter."
