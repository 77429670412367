import React, { useState, useEffect } from 'react';


const apiUrl = process.env.REACT_APP_API_BASE_URL;


function RaceOddsCalculator({ onCalculate }) {
  const [distance, setDistance] = useState('');
  const [numRunners, setNumRunners] = useState('');
  const [trackCondition, setTrackCondition] = useState('fast');
  const [numBetsPerHorse, setNumBetsPerHorse] = useState('');
  const [trainerExperience, setTrainerExperience] = useState('');
  const [numFirstPlaceFinishes, setNumFirstPlaceFinishes] = useState('');
  const [totalWinnings, setTotalWinnings] = useState('');
  const [odds, setOdds] = useState(null);

  useEffect(() => {
    if (
      distance &&
      numRunners &&
      numBetsPerHorse &&
      trainerExperience &&
      numFirstPlaceFinishes &&
      totalWinnings
    ) {
      calculateOdds();
    }
  }, [distance, numRunners, trackCondition, numBetsPerHorse, trainerExperience, numFirstPlaceFinishes, totalWinnings]);

  const calculateOdds = () => {
    const calculatedOdds = ((100 / numRunners) *
      distance *
      (trackCondition === "fast" ? 1 : 1.5) *
      (trainerExperience / numFirstPlaceFinishes) +
      totalWinnings) /
      numBetsPerHorse;
    setOdds(calculatedOdds);
    if (onCalculate) {
      onCalculate(calculatedOdds);
    }
  };

  return (
    <div>
      <h2 style={{ marginTop: '32vh' }}>Horse Odds</h2>
      <label className="odds-label" style={{ display: 'block', marginBottom: '1%' }}>
        Distance (in meters):
        <input type="number" value={distance} onChange={(e) => setDistance(e.target.value)} />
      </label>
      <label className="odds-label" style={{ display: 'block', marginBottom: '1%' }}>
        Number of runners:
        <input type="number" value={numRunners} onChange={(e) => setNumRunners(e.target.value)} />
      </label>
      <div className="odds-label" style={{ display: "flex", alignItems: "center", marginBottom: '1%', width: '42%' }}>
        <b style={{ width: '80%', marginRight: '1%', color: 'black', fontSize: '20px' }}>Track condition:</b>
        <select
          value={trackCondition}
          onChange={(e) => setTrackCondition(e.target.value)}
          style={{ marginLeft: '1%' }}
        >
          <option value="fast">Fast</option>
          <option value="wet">Wet</option>
          <option value="muddy">Muddy</option>
        </select>
      </div>
      <label className="odds-label" style={{ display: 'block', marginBottom: '1%' }}>
        Number of bets per horse:
        <input type="number" value={numBetsPerHorse} onChange={(e) => setNumBetsPerHorse(e.target.value)} />
      </label>
      <label className="odds-label" style={{ display: 'block', marginBottom: '1%' }}>
        Trainer experience:
        <input type="number" value={trainerExperience} onChange={(e) => setTrainerExperience(e.target.value)} />
      </label>
      <label className="odds-label" style={{ display: 'block', marginBottom: '1%' }}>
        Number of first place finishes:
        <input type="number" value={numFirstPlaceFinishes} onChange={(e) => setNumFirstPlaceFinishes(e.target.value)} />
      </label>
      <label className="odds-label" style={{ display: 'block', marginBottom: '1%' }}>
        Total winnings:
        <input type="number" value={totalWinnings} onChange={(e) => setTotalWinnings(e.target.value)} />
      </label>
      <br />
      <div>
        <b>Calculated Odds:</b> {odds !== null ? odds.toFixed(2) : 'Not calculated yet'}
      </div>
    </div>
  );
}

export default RaceOddsCalculator;
