import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_BASE_URL;

// Main function to fetch track data and calculate race start times
const CalculateStartTimes = async (trackId) => {
  try {
    // Fetching track details from the API
    const trackResponse = await axios.get(`${apiUrl}/tracks/${trackId}`);
    const trackData = trackResponse.data;

    // Checking if essential track details are available
    if (!trackData.start_time || !trackData.daily_races || !trackData.race_intervals) {
      throw new Error("Track data is incomplete");
    }

    // Setting the initial start time from track data
    const startTime = new Date();
    const [startHours, startMinutes, startSeconds] = trackData.start_time.split(":");  //Breaking apart the Start Time field
    startTime.setHours(startHours);
    startTime.setMinutes(startMinutes);
    startTime.setSeconds(startSeconds);

    const dailyRaces = trackData.daily_races;
    const raceIntervals = trackData.race_intervals;
    let firstRaceTime = null;

    // Loop through all races of the day to set their start times
    for (let i = 1; i <= dailyRaces; i++) {
      const raceId = trackData[`race_id${i}`];
      if (raceId) {
        // Calculating the start time for each race
        const raceStartTime = new Date(startTime.getTime() + (i - 1) * raceIntervals * 60000);
        const formattedTime = raceStartTime.toTimeString().split(' ')[0]; // convert the start times to proper format

        // Updating race times in 'races' and 'current_races' tables
        await updateRaceTime('races', raceId, formattedTime);
        await updateRaceTime('current_races', raceId, formattedTime);

        // Storing the start time of the first race
        if (i === 1) {
          firstRaceTime = raceStartTime;
        }
      } else {
        console.log(`No valid race ID found for index ${i}`);
      }
    }

    return firstRaceTime;
  } catch (error) {
    console.error("Error fetching and calculating start times:", error);
    throw error;
  }
};

// Helper function to update race times in database tables
const updateRaceTime = async (tableName, raceId, time) => {
  let url;
  let data;

  // Determine URL and data structure based on table name
  if (tableName === 'races') {
    url = `${apiUrl}/${tableName}/${raceId}`; // Direct access via primary key
    data = { starting_time: time };
  } else if (tableName === 'current_races') {
    url = `${apiUrl}/horseracing/${tableName}/race_id/${raceId}`; // Access via the race_id field
    data = { post_time: time };
  }

  try {
    // Patch request to update race time
    const response = await axios.patch(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    // console.log(`Updated ${tableName} table for Race ID ${raceId} with time: ${time}`, response.data);
  } catch (error) {
    console.error(`Failed to update for Race ID ${raceId}:`, error.response ? error.response.data : error.message);
  }
};

// Exporting the main function
export default CalculateStartTimes;

